import { MenuItem, Select, SelectProps } from "@mui/material";
import React from "react";

const identifyingDocuments: number[] = [37, 38, 39, 40];

const documentTypeIds: { [key: number]: string } = {
  37: "State issued driver's license",
  38: "State/Local/Tribe issued ID",
  39: "US Passport",
  40: "Foreign Passport",
};

type BOIRFormDocumentTypeComponentProps = SelectProps & {
  error?: boolean;
  size?: "small" | "medium";
  disabled?: boolean;
  value?: string;
};

export const BOIRFormIdentifyingDocumentComponent = React.forwardRef<
  HTMLSelectElement,
  BOIRFormDocumentTypeComponentProps
>(({ error, size, disabled, value = "", ...props }, ref) => {
  return (
    <Select ref={ref} error={error} size={size} disabled={disabled} value={value} {...props}>
      {identifyingDocuments.map((type) => (
        <MenuItem key={type} value={type}>
          {documentTypeIds[type] || "Select document type"}
        </MenuItem>
      ))}
    </Select>
  );
});

// Add a display name for better debugging
BOIRFormIdentifyingDocumentComponent.displayName = "BOIRFormIdentifyingDocumentComponent";
