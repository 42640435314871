import { Typography, Box } from "@mui/material";
const bannerImage = process.env.PUBLIC_URL + "/banner.jpg";

export const HomeBanner = () => {
  return (
    <Box
      sx={{
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${bannerImage})`, // Adding a dark overlay
        backgroundSize: "cover",
        backgroundPosition: "center 120%",
        backgroundAttachment: "fixed",
        padding: 0,
        textAlign: "left",
        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
        color: "white",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-start",
        paddingLeft: "10%",
        height: "28vh",
        position: "relative",
        width: "100%",
        marginLeft: "auto",
        marginRight: "auto",
      }}
    >
      <Typography
        variant="h1"
        sx={{
          fontSize: "2.5rem",
          fontFamily: "Georgia, serif", // Changed to a serif font
          mb: "0.5rem",
          color: "#FFFFFF", // White center color
          textShadow: `
      -2px -1px 0 #000,  /* Top left */
      2px -1px 0 #000,   /* Top right */
      -2px 1px 0 #000,   /* Bottom left */
      2px 1px 0 #000     /* Bottom right */
    `, // Creates a thin black outline
        }}
      >
        BOIR Filing Platform
      </Typography>
      <Typography
        variant="h2"
        sx={{
          fontSize: "1.25rem",
          fontWeight: "normal",
          fontFamily: "Georgia, serif", // Changed to a serif font
          textShadow: "1px 1px 4px rgba(0, 0, 0, 0.7)",
        }}
      >
        Your Full Compliance Review and Filing
      </Typography>
    </Box>
  );
};
