import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import FAQ from "./pages/faq/FAQ";
import Layout from "./pages/layout/Layout";
import Dashboard from "./pages/Dashboard";
import Home from "./pages/home/Home";
import { CircularProgress, Box, Typography } from "@mui/material";
import { useAuthStateListener } from "./hooks/useAuthStateListener";
import { ResetPassword } from "./pages/userauth/ResetPassword";
import { SignUp } from "./pages/userauth/SignUp";
import { SignIn } from "./pages/userauth/SignIn";

// Load Stripe with your publishable key
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const App = () => {
  useAuthStateListener();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsLoading(false);
    }, 1000);

    return () => clearTimeout(timeout);
  }, []);

  if (isLoading) {
    return (
      <Box sx={{ textAlign: "center", marginTop: 10 }}>
        <CircularProgress />
        <Typography variant="h6" sx={{ marginTop: 2 }}>
          Loading, please wait...
        </Typography>
      </Box>
    );
  }

  return (
    <Elements stripe={stripePromise}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="faq" element={<FAQ />} />
            <Route path="sign-in" element={<SignIn />} />
            <Route path="reset-password" element={<ResetPassword />} />
            <Route path="sign-up" element={<SignUp />} />
            <Route path="dashboard" element={<Dashboard />} />
          </Route>
          <Route path="*" element={<Home />} />
        </Routes>
      </BrowserRouter>
    </Elements>
  );
};

export default App;
