import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  TextField,
  Button,
  Box,
  Alert,
  Typography,
  CircularProgress,
  Stack,
} from "@mui/material";
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
  updateProfile,
} from "firebase/auth";
import { auth } from "../../auth/auth.js";
import TermsAndConditions from "../home/components/TermsAndConditions.js";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

export const SignUp = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [signUpEmailPasswordNotice, setSignUpEmailPasswordNotice] = useState("");
  const [termsAccepted, setTermsAccepted] = useState(
    localStorage.getItem("termsAccepted") === "true"
  );
  const [emailSent, setEmailSent] = useState(
    localStorage.getItem("signupStep") === "emailVerification"
  );
  const [resendEmailStatus, setResendEmailStatus] = useState("");
  const [loading, setLoading] = useState(false);

  // Handles email/password sign-up, stores UID, and moves to email verification step
  const handleEmailPasswordSignUp = async () => {
    if (password !== confirmPassword) {
      setSignUpEmailPasswordNotice("Passwords don't match. Please try again.");
      return;
    }

    try {
      setLoading(true);
      // Create the user with email and password
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      // Use the company name as the display name
      await updateProfile(user, {
        displayName: companyName,
      });

      // Send email verification
      await sendEmailVerification(user);

      setSignUpEmailPasswordNotice("A verification email has been sent to your email address.");
      setEmailSent(true);
      localStorage.setItem("pendingUserUid", user.uid);
      localStorage.setItem("signupStep", "emailVerification");
    } catch (error) {
      console.error("Sign-up error:", error);
      let errorMessage = "An error occurred. Please try again.";

      // Handle specific Firebase errors
      switch (error.code) {
        case "auth/email-already-in-use":
          errorMessage = "This email is already registered. Please sign in instead.";
          break;
        case "auth/invalid-email":
          errorMessage = "Please enter a valid email address.";
          break;
        case "auth/weak-password":
          errorMessage = "Password should be at least 6 characters long.";
          break;
        default:
          errorMessage = error.message;
      }

      setSignUpEmailPasswordNotice(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  const handleResendVerificationEmail = async () => {
    try {
      const user = auth.currentUser;
      if (user) {
        await sendEmailVerification(user);
        setResendEmailStatus("Verification email resent!");
      }
    } catch (error) {
      setResendEmailStatus("Error resending verification email.");
    }
  };

  // Poll to check email verification status using the user's UID
  useEffect(() => {
    const interval = setInterval(async () => {
      const userUid = localStorage.getItem("pendingUserUid");
      if (auth.currentUser && auth.currentUser.uid === userUid) {
        await auth.currentUser.reload(); // Reload user data to check email verification status
        if (auth.currentUser.emailVerified) {
          clearInterval(interval); // Stop polling once verified
          localStorage.removeItem("pendingUserUid");
          localStorage.removeItem("signupStep");

          // Get the user's ID token
          const token = await auth.currentUser.getIdToken();

          // Dispatch the auth state update
          dispatch({
            type: "user/updateAuthState",
            payload: {
              isAuthenticated: true,
              emailVerified: true,
              idToken: token,
              error: null,
            },
          });

          navigate("/");
        }
      }
    }, 5000);

    return () => clearInterval(interval);
  }, [navigate, dispatch]);

  return (
    <Stack
      direction={{ xs: "column", sm: "row" }}
      alignItems="flex-start"
      justifyContent="center"
      spacing={2}
    >
      <Card>
        <CardHeader title={"Sign Up"} />
        <CardContent>
          {loading ? (
            <Box sx={{ textAlign: "center" }}>
              <CircularProgress />
              <Typography variant="h6" sx={{ marginTop: 2 }}>
                Processing your sign-up...
              </Typography>
            </Box>
          ) : !termsAccepted ? (
            <TermsAndConditions
              onAccept={() => {
                setTermsAccepted(true);
                localStorage.setItem("termsAccepted", "true");
              }}
            />
          ) : emailSent ? (
            <Box sx={{ textAlign: "center" }}>
              <Typography variant="h6" sx={{ marginBottom: 2 }}>
                Please check your email to verify your account.
              </Typography>
              <Button
                variant="contained"
                onClick={handleResendVerificationEmail}
                sx={{ marginBottom: 2 }}
              >
                Resend Verification Email
              </Button>
              {resendEmailStatus && (
                <Typography variant="body2" color="primary">
                  {resendEmailStatus}
                </Typography>
              )}
            </Box>
          ) : (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "300px",
                margin: "auto",
              }}
            >
              <TextField
                label="First Name"
                variant="outlined"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                sx={{ marginBottom: 2 }}
              />
              <TextField
                label="Last Name"
                variant="outlined"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                sx={{ marginBottom: 2 }}
              />
              <TextField
                label="Reporting Company Name"
                variant="outlined"
                value={companyName}
                onChange={(e) => setCompanyName(e.target.value)}
                sx={{ marginBottom: 2 }}
              />
              <TextField
                label="Email"
                variant="outlined"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                sx={{ marginBottom: 2 }}
              />
              <TextField
                label="Password"
                type="password"
                variant="outlined"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                sx={{ marginBottom: 2 }}
              />
              <TextField
                label="Confirm Password"
                type="password"
                variant="outlined"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                sx={{ marginBottom: 2 }}
              />

              {signUpEmailPasswordNotice && (
                <Alert severity="warning" sx={{ marginBottom: 2 }}>
                  {signUpEmailPasswordNotice}
                </Alert>
              )}

              <Button
                variant="contained"
                onClick={handleEmailPasswordSignUp}
                sx={{ marginBottom: 2 }}
                disabled={loading}
              >
                Sign Up
              </Button>
            </Box>
          )}

          <Typography>
            Already have an account?
            <Button onClick={() => navigate("/sign-in")}>Sign in</Button>
          </Typography>
        </CardContent>
      </Card>
    </Stack>
  );
};
