import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { BOIRFormFilingInformation } from "./BOIRFormFilingInformation";
import { BOIRFormReportingCompany } from "./BOIRFormReportingCompany";
import { BOIRCompanyApplicants } from "./BOIRCompanyApplicants";
import { BOIRBeneficialOwners } from "./BOIRBeneficialOwners";
import { BOIRFormSubmitterInfo } from "./BOIRFormSubmitterInfo";
import { FormProvider, useForm } from "react-hook-form";
import { styled } from "@mui/material/styles";
import { useEffect, useState } from "react";
import { addDoc, collection, getDocs, query, serverTimestamp, where } from "firebase/firestore";
import { getFormDefaults } from "./BOIRFormDefaults";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { StripePaymentComponent } from "../../../../features/stripe/StripePaymentComponent";
import { auth, db } from "../../../../auth/auth";
import CircularProgress from "@mui/material/CircularProgress";

const StyledAccordion = styled(Accordion)(({ theme, error }) => ({
  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
  "&:before": {
    display: "none",
  },
  margin: "8px 0",
  borderRadius: "4px",
  border: error ? `1px solid ${theme.palette.error.main}` : "none",
  "&:first-of-type": {
    borderTopLeftRadius: "4px",
    borderTopRightRadius: "4px",
  },
  "&:last-of-type": {
    borderBottomLeftRadius: "4px",
    borderBottomRightRadius: "4px",
  },
  "&.error": {
    border: `1px solid ${theme.palette.error.main}`,
  },
  "&.Mui-disabled .MuiAccordionSummary-root": {
    opacity: 0.7,
  },
}));

const PaymentModal = ({ open, onClose, onComplete }) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>
        Complete Payment to Continue
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <StripePaymentComponent onComplete={onComplete} />
      </DialogContent>
    </Dialog>
  );
};

export const BOIRForm = ({ initiateSubmission }) => {
  const methods = useForm({
    defaultValues: getFormDefaults(),
  });

  const {
    formState: { errors },
  } = methods;
  const hasSubmitterErrors = Object.keys(errors?.submmitterInfo || {}).length > 0;
  const hasFilingErrors = Object.keys(errors?.filingInformation || {}).length > 0;
  const hasReportingCompanyErrors = Object.keys(errors?.reportingCompany || {}).length > 0;
  const hasCompanyApplicantsErrors = Object.keys(errors?.companyApplicants || {}).length > 0;
  const hasBeneficialOwnersErrors = Object.keys(errors?.beneficialOwners || {}).length > 0;

  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const checkPaymentStatus = async () => {
    try {
      const firebaseUser = auth.currentUser;
      if (firebaseUser) {
        const paymentQuery = query(
          collection(db, "payments"),
          where("userId", "==", firebaseUser.uid)
        );
        const paymentSnap = await getDocs(paymentQuery);
        return !paymentSnap.empty;
      }
    } catch (error) {
      console.error("Error checking payment status:", error);
      throw error;
    }
  };

  const completePayment = async () => {
    const firebaseUser = auth.currentUser;
    if (firebaseUser) {
      try {
        await addDoc(collection(db, "payments"), {
          userId: firebaseUser.uid,
          timestamp: serverTimestamp(),
          status: "completed",
          amount: 99,
        });
      } catch (error) {
        console.error("Error saving payment:", error);
      }
    }
  };

  const onSubmit = async (data) => {
    setIsSubmitting(true);
    if (!(await checkPaymentStatus())) {
      setShowPaymentModal(true);
      setIsSubmitting(false);
      return;
    }

    setIsSubmitting(false);
    initiateSubmission(data);
  };

  const onError = (errors) => {
    console.log(errors);
    console.log(methods.getValues());
  };

  const yearFormed = methods.watch("reportingCompany.yearFormed");
  const typeOfFiling = methods.watch("filingInformation.typeOfFiling");

  const [expandedSections, setExpandedSections] = useState({
    reportingCompany: false,
    companyApplicants: false,
    beneficialOwners: false,
  });

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (yearFormed) {
        methods.clearErrors("companyApplicants");
      }
    }, 300);
    if (yearFormed && yearFormed < 2024) {
      setExpandedSections((prev) => ({ ...prev, companyApplicants: false }));
    }
    return () => clearTimeout(timeoutId);
  }, [yearFormed, methods]);

  useEffect(() => {
    if (typeOfFiling === "exempt") {
      methods.clearErrors("reportingCompany");
      methods.clearErrors("companyApplicants");
      methods.clearErrors("beneficialOwners");
      setExpandedSections((prev) => ({
        ...prev,
        reportingCompany: false,
        companyApplicants: false,
        beneficialOwners: false,
      }));
    }
  }, [typeOfFiling, methods]);

  const handleAccordionChange = (section) => (event, isExpanded) => {
    setExpandedSections((prev) => ({ ...prev, [section]: isExpanded }));
  };

  return (
    <FormProvider {...methods}>
      <Box
        display="flex"
        flexDirection={{ xs: "column" }}
        alignItems={"center"}
        gap={6}
        boxShadow={2}
        padding={4}
        bgcolor={"background.paper"}
        borderRadius={3}
      >
        <Typography variant="h4">Beneficial Ownership Information Report</Typography>
        <Box width={"100%"} display={"flex"} flexDirection={{ xs: "column" }} gap={1}>
          <StyledAccordion className={hasSubmitterErrors ? "error" : ""}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              sx={{
                color: hasSubmitterErrors ? "error.main" : "inherit",
              }}
            >
              <Typography>Submitter Information</Typography>
              {hasSubmitterErrors && (
                <Typography color="error" sx={{ ml: 2, fontSize: "0.875rem" }}>
                  (Contains errors)
                </Typography>
              )}
            </AccordionSummary>
            <AccordionDetails>
              <BOIRFormSubmitterInfo />
            </AccordionDetails>
          </StyledAccordion>
          <StyledAccordion className={hasFilingErrors ? "error" : ""}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              sx={{
                color: hasFilingErrors ? "error.main" : "inherit",
              }}
            >
              <Typography>Filing Information</Typography>
              {hasFilingErrors && (
                <Typography color="error" sx={{ ml: 2, fontSize: "0.875rem" }}>
                  (Contains errors)
                </Typography>
              )}
            </AccordionSummary>
            <AccordionDetails>
              <BOIRFormFilingInformation />
            </AccordionDetails>
          </StyledAccordion>

          <StyledAccordion
            expanded={expandedSections.reportingCompany}
            onChange={handleAccordionChange("reportingCompany")}
            className={hasReportingCompanyErrors ? "error" : ""}
            disabled={typeOfFiling === "exempt"}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              sx={{
                color: hasReportingCompanyErrors ? "error.main" : "inherit",
              }}
            >
              <Typography>
                Part I. Reporting Company Information
                {typeOfFiling === "exempt" && (
                  <Typography
                    component="span"
                    fontWeight={700}
                    sx={{
                      display: "block",
                      mt: 0.5,
                      color: "primary.main",
                      fontSize: "0.95rem",
                      fontStyle: "italic",
                    }}
                  >
                    Note: No reporting company information is required for newly exempt type of
                    filing
                  </Typography>
                )}
              </Typography>

              {hasReportingCompanyErrors && (
                <Typography color="error" sx={{ ml: 2, fontSize: "0.875rem" }}>
                  (Contains errors)
                </Typography>
              )}
            </AccordionSummary>
            <AccordionDetails>
              {typeOfFiling !== "exempt" && <BOIRFormReportingCompany />}
            </AccordionDetails>
          </StyledAccordion>

          <StyledAccordion
            expanded={expandedSections.companyApplicants}
            onChange={handleAccordionChange("companyApplicants")}
            className={hasCompanyApplicantsErrors ? "error" : ""}
            disabled={(yearFormed && yearFormed < 2024) || typeOfFiling === "exempt"}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              sx={{
                color: hasCompanyApplicantsErrors ? "error.main" : "inherit",
              }}
            >
              <Typography>
                Part II. Company Applicant(s) Information
                {!(typeOfFiling === "exempt") && yearFormed && yearFormed < 2024 && (
                  <Typography
                    component="span"
                    fontWeight={700}
                    sx={{
                      display: "block",
                      mt: 0.5,
                      color: "primary.main",
                      fontSize: "0.95rem",
                      fontStyle: "italic",
                    }}
                  >
                    Note: reporting company was formed before 2024, no company applicant information
                    is required
                  </Typography>
                )}
                {typeOfFiling === "exempt" && (
                  <Typography
                    component="span"
                    fontWeight={700}
                    sx={{
                      display: "block",
                      mt: 0.5,
                      color: "primary.main",
                      fontSize: "0.95rem",
                      fontStyle: "italic",
                    }}
                  >
                    Note: No company applicant information is required for newly exempt type of
                    filing
                  </Typography>
                )}
              </Typography>
              {hasCompanyApplicantsErrors && (
                <Typography color="error" sx={{ ml: 2, fontSize: "0.875rem" }}>
                  (Contains errors)
                </Typography>
              )}
            </AccordionSummary>
            <AccordionDetails>
              {(yearFormed === "" || yearFormed >= 2024) && typeOfFiling !== "exempt" && (
                <BOIRCompanyApplicants />
              )}
            </AccordionDetails>
          </StyledAccordion>

          <StyledAccordion
            expanded={expandedSections.beneficialOwners}
            onChange={handleAccordionChange("beneficialOwners")}
            className={hasBeneficialOwnersErrors ? "error" : ""}
            disabled={typeOfFiling === "exempt"}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              sx={{
                color: hasBeneficialOwnersErrors ? "error.main" : "inherit",
              }}
            >
              <Typography>
                Part III. Beneficial Owner(s) Information
                {typeOfFiling === "exempt" && (
                  <Typography
                    component="span"
                    fontWeight={700}
                    sx={{
                      display: "block",
                      mt: 0.5,
                      color: "primary.main",
                      fontSize: "0.95rem",
                      fontStyle: "italic",
                    }}
                  >
                    Note: No beneficial owner information is required for newly exempt type of
                    filing
                  </Typography>
                )}
              </Typography>
              {hasBeneficialOwnersErrors && (
                <Typography color="error" sx={{ ml: 2, fontSize: "0.875rem" }}>
                  (Contains errors)
                </Typography>
              )}
            </AccordionSummary>
            <AccordionDetails>
              {typeOfFiling !== "exempt" && <BOIRBeneficialOwners />}
            </AccordionDetails>
          </StyledAccordion>
        </Box>
      </Box>
      <PaymentModal
        open={showPaymentModal}
        onClose={() => setShowPaymentModal(false)}
        onComplete={() => completePayment()}
      />
      <Box display="flex" justifyContent="flex-end" width="100%" mt={4}>
        <Button
          variant="contained"
          size="large"
          onClick={methods.handleSubmit(onSubmit, onError)}
          disabled={isSubmitting}
          sx={{
            minWidth: "120px",
            "& .MuiCircularProgress-root": {
              marginLeft: 1,
            },
          }}
        >
          Continue
          {isSubmitting && <CircularProgress size={20} color="inherit" />}
        </Button>
      </Box>
    </FormProvider>
  );
};
