import React, { useCallback, useState } from "react";
import {
  Box,
  Typography,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
} from "@mui/material";
import { Upload as UploadIcon, Delete as DeleteIcon } from "@mui/icons-material";
import { useDropzone } from "react-dropzone";

const ACCEPTED_FILE_TYPES = {
  "application/pdf": [".pdf"],
  "image/jpeg": [".jpg", ".jpeg"],
  "image/png": [".png"],
};

export const FileDropzone = ({ onFileChange, maxFiles = 1 }) => {
  const [files, setFiles] = useState([]);

  const onDrop = useCallback(
    (acceptedFiles) => {
      if (files.length + acceptedFiles.length > maxFiles) {
        return;
      }
      setFiles((prev) => [...prev, ...acceptedFiles]);
      onFileChange?.(acceptedFiles);
    },
    [onFileChange, files.length, maxFiles]
  );

  const removeFile = (fileToRemove) => {
    setFiles(files.filter((file) => file !== fileToRemove));
    onFileChange?.(null);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: ACCEPTED_FILE_TYPES,
    maxSize: 4194304, // 4MB
  });

  return (
    <Box sx={{ width: "100%" }}>
      <Box
        {...getRootProps()}
        sx={{
          border: "2px dashed",
          borderColor: isDragActive ? "primary.main" : "grey.300",
          borderRadius: 2,
          p: 3,
          mb: 2,
          bgcolor: isDragActive ? "action.hover" : "background.paper",
          cursor: "pointer",
          transition: "all 0.2s ease",
          "&:hover": {
            borderColor: "primary.main",
            bgcolor: "action.hover",
          },
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <input {...getInputProps()} />
        <UploadIcon color="primary" sx={{ fontSize: 40, mb: 1 }} />
        <Typography variant="body1" color="textSecondary" align="center">
          {isDragActive
            ? "Drop the files here..."
            : "Drag & drop files here, or click to select files"}
        </Typography>
        <Typography variant="caption" color="textSecondary" align="center" sx={{ mt: 1 }}>
          Accepted files: PDF, JPG, JPEG, PNG (max 4MB)
        </Typography>
      </Box>

      {files.length > 0 && (
        <List>
          {files.map((file, index) => (
            <ListItem
              key={index}
              sx={{
                border: "1px solid",
                borderColor: "grey.200",
                borderRadius: 1,
                mb: 1,
              }}
            >
              <ListItemText
                primary={file.name}
                secondary={`${(file.size / 1024 / 1024).toFixed(2)} MB`}
              />
              <ListItemSecondaryAction>
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={() => removeFile(file)}
                  size="small"
                >
                  <DeleteIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      )}
    </Box>
  );
};
