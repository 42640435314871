const FIREBASE_UID_LENGTH = 20; // TODO - check length of firebase id
const NAME_LENGTH = 9; // TODO - set to length in DB
const MAX_EMAIL_LENGTH = 254; // TODO - set to length in DB
const MIN_PHONE_LENGTH = 10;
const MAX_PHONE_LENGTH = 14;

export const isValidFirebaseUid = (firebaseUid) => {
  const firebaseUidRegex = /^[A-Za-z0-9_-]$/;
  return firebaseUidRegex.test(firebaseUid) && firebaseUid.length < FIREBASE_UID_LENGTH;
};

export const isValidName = (nameValue) => {
  return typeof nameValue === "string" && nameValue.length < NAME_LENGTH;
};

export const isValidEmail = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^ s@]+$/;
  return emailRegex.test(email) && email.length < MAX_EMAIL_LENGTH;
};

export const isValidPhoneNumber = (phoneNumber) => {
  if (typeof phoneNumber !== "string") {
    return false;
  }

  // Strip the +1 prefix if present
  const normalizedPhone = phoneNumber.startsWith("+1") ? phoneNumber.slice(2) : phoneNumber;
  // Regular expression to match U.S. phone numbers with optional common separators
  const usPhoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
  // Remove common separators to check the length of the numeric part only
  const numericPhone = normalizedPhone.replace(/[-. ()]/g, "");
  // Check if the phone number matches the regex pattern and its length is within the allowed range
  return (
    usPhoneRegex.test(numericPhone) &&
    numericPhone.length >= MIN_PHONE_LENGTH &&
    numericPhone.length <= MAX_PHONE_LENGTH
  );
};
