import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import FinCEN from "../../models/fincen/Fincen";
import { api, AppDispatch, RootState } from "../../app/store";

export enum OperationStatus {
  IDLE = "idle",
  LOADING = "loading",
  SUCCEEDED = "succeeded",
  FAILED = "failed",
}

export enum SubmissionStatus {
  INITIATED = "submission_initiated",
  PROCESSING = "submission_processing",
  ACCEPTED = "submission_accepted",
  REJECTED = "submission_rejected",
  FAILED = "submission_failed",
  VALIDATION_PASSED = "submission_validation_passed",
  VALIDATION_FAILED = "submission_validation_failed",
}

export type InitialStateType = {
  fincen: {
    accessToken: string;
    scope: string;
    tokenType: string;
    expiresIn: number;
    processId: string;
  };
  uploadedAttachments: string[];
  xmlData?: string;
  authorizationStatus: OperationStatus;
  authorizationError?: string;
  submissionStatus: OperationStatus;
  submissionError?: string;
  initiateSubmissionStatus: OperationStatus;
  initiateSubmissionError?: string;
  attachmentStatus: OperationStatus;
  attachmentError?: string;
  submissionData: {
    pdfBinary: string;
    status: { submissionStatus: SubmissionStatus; processId: string; vallidationErrors: string[] };
  };
};

export const fincenSliceName = "fincen";

export const fetchBearerToken = createAsyncThunk<
  {
    access_token: string;
    scope: string;
    token_type: string;
    expires_in: number;
  },
  void,
  { state: RootState }
>("fincen/fetchBearerToken", async (_, { getState }) => {
  const { user } = getState() as RootState;
  const idToken = user.auth.idToken;

  try {
    const response = await api.post("/fincen/authorization-intent", _, {
      headers: {
        Authorization: `Bearer ${idToken}`,
      },
    });
    return await response.data;
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(`Failed to fetch bearer token: ${error.message}`);
    } else {
      throw new Error("Failed to fetch bearer token: An unknown error occurred");
    }
  }
});

export const initiateSubmission = createAsyncThunk<string, void, { state: RootState }>(
  "fincen/initiateSubmission",
  async (_, { getState }) => {
    const { fincen } = getState();
    console.log(fincen);
    const response = await fetch(`/fincen/processId`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${fincen.fincen.accessToken}`,
        Accept: "*/*",
        "Cache-Control": "no-cache",
      },
    });

    if (!response.ok) {
      const errorDetails = await response.text();
      throw new Error(
        `Failed to initiate submission intent: ${response.statusText}. Details: ${errorDetails}`
      );
    }

    const data = await response.json();
    return data.processId;
  }
);

export const fetchTokenAndInitiateSubmission = createAsyncThunk<
  string,
  void,
  { dispatch: AppDispatch }
>("fincen/fetchTokenAndInitiateSubmission", async (_, { dispatch }) => {
  const tokenResult = await dispatch(fetchBearerToken());
  if (fetchBearerToken.rejected.match(tokenResult)) {
    throw new Error("Failed to fetch bearer token");
  }

  const submissionResult = await dispatch(initiateSubmission());
  if (initiateSubmission.rejected.match(submissionResult)) {
    throw new Error("Failed to initiate submission");
  }

  return submissionResult.payload;
});

export const uploadAttachment = createAsyncThunk<
  { status: string; message: string },
  { fileName: string; fileData: File },
  { state: RootState }
>("fincen/uploadAttachment", async ({ fileName, fileData }, { getState }) => {
  const { fincen } = getState();
  const processId = fincen.fincen.processId;

  if (!processId) {
    throw new Error("Process ID is not available. Initiate submission first.");
  }

  if (fileData.size > 4 * 1024 * 1024) {
    throw new Error("File size must not exceed 4MB");
  }

  const validExtensions = [".jpg", ".jpeg", ".png", ".pdf"];
  const fileExtension = fileName.toLowerCase().match(/\.[^.]*$/)?.[0];
  if (!fileExtension || !validExtensions.includes(fileExtension)) {
    throw new Error("File must be a .jpg, .jpeg, .png, or .pdf");
  }

  const fileContents = await fileData.arrayBuffer();

  let contentType;
  switch (fileExtension) {
    case ".pdf":
      contentType = "application/pdf";
      break;
    case ".png":
      contentType = "image/png";
      break;
    default:
      contentType = "image/jpeg";
  }

  const response = await fetch(`/fincen/attachments/${processId}/${fileName}`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${fincen.fincen.accessToken}`,
      "Content-Type": contentType,
    },
    body: fileContents,
  });

  if (!response.ok) {
    const errorText = await response.text();
    throw new Error(`Failed to upload attachment: ${response.statusText}. ${errorText}`);
  }

  const result = await response.json();
  if (result.status === "upload_failed") {
    throw new Error(result.message || "Upload failed");
  }

  return result;
});

export const submitForm = createAsyncThunk<string, { xmlData: string }, { state: RootState }>(
  "fincen/submitForm",
  async ({ xmlData }, { getState }) => {
    const { fincen } = getState();
    const processId = fincen.fincen.processId;
    const accessToken = fincen.fincen.accessToken;

    if (!processId) {
      throw new Error("Process ID is not available. Initiate submission first.");
    }

    const response = await fetch(`/fincen/upload/BOIR/${processId}/boir.xml`, {
      method: "POST",
      headers: {
        "Content-Type": "application/xml",
        Authorization: `Bearer ${accessToken}`,
      },
      body: xmlData,
    });

    if (!response.ok) {
      const errorDetails = await response.text();
      throw new Error(`Failed to submit form: ${response.statusText}. Details: ${errorDetails}`);
    }

    return await response.json();
  }
);

export const fetchSubmissionStatus = createAsyncThunk<
  {
    pdfBinary: string;
    status: { submissionStatus: SubmissionStatus; processId: string; vallidationErrors: string[] };
  },
  void,
  { state: RootState }
>("fincen/fetchSubmissionStatus", async (_, { getState }) => {
  const { fincen } = getState();
  const processId = fincen.fincen.processId;
  const accessToken = fincen.fincen.accessToken;

  const response = await fetch(`/fincen/transcript/${processId}`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${accessToken}`,
      Accept: "application/json",
    },
  });

  if (!response.ok) {
    throw new Error(`Failed to fetch submission status: ${response.statusText}`);
  }

  return await response.json();
});

export const initialState: InitialStateType = {
  fincen: new FinCEN("", "", "", 0, "").formatForState(),
  uploadedAttachments: [],
  xmlData: undefined,
  authorizationStatus: OperationStatus.IDLE,
  authorizationError: undefined,
  submissionStatus: OperationStatus.IDLE,
  submissionError: undefined,
  initiateSubmissionStatus: OperationStatus.IDLE,
  initiateSubmissionError: undefined,
  attachmentStatus: OperationStatus.IDLE,
  attachmentError: undefined,
  submissionData: {
    pdfBinary: "",
    status: {
      submissionStatus: SubmissionStatus.INITIATED,
      processId: "",
      vallidationErrors: [],
    },
  },
};

const fincenSlice = createSlice({
  name: fincenSliceName,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchBearerToken.pending, (state) => {
        state.authorizationStatus = OperationStatus.LOADING;
      })
      .addCase(fetchBearerToken.fulfilled, (state, action) => {
        state.authorizationStatus = OperationStatus.SUCCEEDED;
        const newFinCEN = new FinCEN(
          action.payload.access_token,
          action.payload.scope,
          action.payload.token_type,
          action.payload.expires_in,
          ""
        );
        state.fincen = newFinCEN.formatForState();
      })
      .addCase(fetchBearerToken.rejected, (state, action) => {
        state.authorizationStatus = OperationStatus.FAILED;
        state.authorizationError = action.error.message;
      })
      .addCase(initiateSubmission.pending, (state) => {
        state.initiateSubmissionStatus = OperationStatus.LOADING;
      })
      .addCase(initiateSubmission.fulfilled, (state, action) => {
        state.initiateSubmissionStatus = OperationStatus.SUCCEEDED;
        state.fincen.processId = action.payload;
      })
      .addCase(initiateSubmission.rejected, (state, action) => {
        state.initiateSubmissionStatus = OperationStatus.FAILED;
        state.initiateSubmissionError = action.error.message;
      })
      .addCase(fetchTokenAndInitiateSubmission.pending, (state) => {
        state.authorizationStatus = OperationStatus.LOADING;
        state.initiateSubmissionStatus = OperationStatus.LOADING;
        state.submissionData.status.submissionStatus = SubmissionStatus.INITIATED;
      })
      .addCase(fetchTokenAndInitiateSubmission.fulfilled, (state, action) => {
        state.authorizationStatus = OperationStatus.SUCCEEDED;
        state.initiateSubmissionStatus = OperationStatus.SUCCEEDED;
        state.fincen.processId = action.payload;
      })
      .addCase(fetchTokenAndInitiateSubmission.rejected, (state, action) => {
        state.authorizationStatus = OperationStatus.FAILED;
        state.initiateSubmissionStatus = OperationStatus.FAILED;
        state.authorizationError = action.error.message;
        state.initiateSubmissionError = action.error.message;
      })
      .addCase(uploadAttachment.pending, (state) => {
        state.attachmentStatus = OperationStatus.LOADING;
      })
      .addCase(uploadAttachment.fulfilled, (state, action) => {
        state.attachmentStatus = OperationStatus.SUCCEEDED;
        // Store uploaded file names
        state.uploadedAttachments = state.uploadedAttachments || [];
        state.uploadedAttachments.push(action.meta.arg.fileName);
      })
      .addCase(uploadAttachment.rejected, (state, action) => {
        state.attachmentStatus = OperationStatus.FAILED;
        state.attachmentError = action.error.message;
      })
      .addCase(submitForm.pending, (state, action) => {
        state.submissionStatus = OperationStatus.LOADING;
        state.xmlData = action.meta.arg.xmlData;
      })
      .addCase(submitForm.fulfilled, (state) => {
        state.submissionStatus = OperationStatus.SUCCEEDED;
      })
      .addCase(submitForm.rejected, (state, action) => {
        state.submissionStatus = OperationStatus.FAILED;
        state.submissionError = action.error.message;
      })
      .addCase(fetchSubmissionStatus.pending, (state) => {
        state.submissionStatus = OperationStatus.LOADING;
      })
      .addCase(fetchSubmissionStatus.fulfilled, (state, action) => {
        state.submissionStatus = OperationStatus.SUCCEEDED;
        state.submissionData = {
          pdfBinary: action.payload.pdfBinary,
          status: action.payload.status,
        };
      })
      .addCase(fetchSubmissionStatus.rejected, (state, action) => {
        state.submissionStatus = OperationStatus.FAILED;
        state.submissionError = action.error.message;
      })
      .addCase("fincen/reset", () => {
        return initialState;
      });
  },
});

export default fincenSlice.reducer;
