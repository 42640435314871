import React from "react";
import { Stepper, Step, StepButton, Box } from "@mui/material";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { useTheme } from "@mui/material/styles";

export type CustomStepIconProps = {
  active: boolean;
  completed: boolean;
  enabled: boolean;
  status?: "error" | "completed";
};

const CustomStepIcon = (props: CustomStepIconProps) => {
  const theme = useTheme();
  const { active, completed, enabled, status } = props;

  return (
    <div
      style={{
        color: active
          ? theme.palette.stepper.primary
          : completed || status === "completed"
            ? theme.palette.stepper.primary
            : enabled
              ? theme.palette.stepper.light
              : theme.palette.stepper.gray,
        width: 40,
        height: 40,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "transparent",
      }}
    >
      {status === "error" ? (
        <CancelIcon sx={{ color: theme.palette.error.main }} />
      ) : completed || status === "completed" ? (
        <CheckCircleIcon />
      ) : (
        <RadioButtonUncheckedIcon />
      )}
    </div>
  );
};

export type ClickableStepperProps = {
  slides: Slide[];
  activeStep: number;
  setActiveStep: (step: number) => void;
};

export type Slide = {
  title: string;
  enabled: boolean;
  content: React.ReactNode;
  status?: "error" | "completed";
};

const ClickableStepper = ({ slides, activeStep, setActiveStep }: ClickableStepperProps) => {
  const handleStep = (step: number) => () => {
    setActiveStep(step);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Stepper nonLinear activeStep={activeStep}>
        {slides.map((slide: Slide, index: number) => (
          <Step key={index}>
            <StepButton
              color="inherit"
              disabled={!slide.enabled}
              onClick={handleStep(index)}
              icon={
                <CustomStepIcon
                  completed={index < activeStep}
                  active={index === activeStep}
                  enabled={slide.enabled}
                  status={slide.status}
                />
              }
            ></StepButton>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};

export default ClickableStepper;
