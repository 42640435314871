import { createSelector } from "@reduxjs/toolkit";
import FinCEN from "../../models/fincen/Fincen";
import { RootState } from "../../app/store";
import { fincenSliceName, InitialStateType } from "./fincenSlice";

export const selectFinCENObject = (state: RootState) => state.fincen.fincen;

export const selectFinCENInstance = createSelector([selectFinCENObject], (fincenObject) => {
  return new FinCEN(
    fincenObject.accessToken,
    fincenObject.scope,
    fincenObject.tokenType,
    fincenObject.expiresIn,
    fincenObject.processId
  );
});

type SelectorCreator<T> = {
  [K in keyof T]: (state: RootState) => T[K];
};

export function createStateSelectors<T extends object>(
  sliceName: keyof RootState
): SelectorCreator<T> {
  const baseSelector = (state: RootState) => state[sliceName];

  return new Proxy({} as SelectorCreator<T>, {
    get: (target, prop: string) => {
      return createSelector(baseSelector, (slice) => (slice as T)[prop as keyof T]);
    },
  });
}

export const fincenSelectors = createStateSelectors<InitialStateType>(fincenSliceName);
