import { useState } from "react";
import { Card, CardHeader, CardContent, Box, TextField, Button, Alert, Stack } from "@mui/material";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";

export const ResetPassword = () => {
  const [email, setEmail] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [resetPasswordNotice, setResetPasswordNotice] = useState("");
  const auth = getAuth();

  const handleForgotPassword = async () => {
    try {
      await sendPasswordResetEmail(auth, email);
      setError(false);
      setSuccess(true);
      setResetPasswordNotice("A password reset email has been sent to " + email);
    } catch (error) {
      setSuccess(false);
      setError(true);
      setResetPasswordNotice(error.message);
    }
  };

  return (
    <Stack
      direction={{ xs: "column", sm: "row" }}
      alignItems="flex-start"
      justifyContent="center"
      spacing={2}
    >
      <Card>
        <CardHeader title={"Reset Password"} />
        <CardContent>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "300px",
              margin: "auto",
            }}
          >
            <TextField
              label="Email"
              variant="outlined"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              sx={{ marginBottom: 2 }}
            />

            <Button variant="contained" onClick={handleForgotPassword} sx={{ marginBottom: 2 }}>
              Reset Password
            </Button>
            {success && (
              <Alert severity="success" sx={{ marginBottom: 2 }}>
                {resetPasswordNotice}
              </Alert>
            )}
            {error && (
              <Alert severity="error" sx={{ marginBottom: 2 }}>
                {resetPasswordNotice}
              </Alert>
            )}
          </Box>
        </CardContent>
      </Card>
    </Stack>
  );
};
