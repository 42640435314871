import React, { useEffect, useState } from "react";
import { Box, CircularProgress, Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { selectUserInstance } from "../../features/user/userSelector";
import { Survey } from "./components/survey/Survey";
import { BOIRForm } from "./components/boir-form/BOIRForm";
import { ProductDescription } from "./components/ProductDescription";
import { SignUpSignInSlide } from "./components/SignUpSignInSlide";
import { FormSubmission } from "./components/submission/FormSubmission";
import { SubmissionStatusDisplay } from "./components/submission/SubmissionStatusDisplay";
import { auth } from "../../auth/auth";
import { BOIRFormData, getFormDefaults } from "./components/boir-form/BOIRFormDefaults";
import ClickableStepper, { Slide } from "./components/ClickableStepper";
import {
  fetchTokenAndInitiateSubmission,
  SubmissionStatus,
  submitForm,
  uploadAttachment,
} from "../../features/fincenData/fincenSlice";
import { AppDispatch, RootState } from "../../app/store";
import { generateBOIRXML } from "./components/xml-files/generateXml";
import { useLocation, useNavigate } from "react-router-dom";
import { fincenSelectors } from "../../features/fincenData/fincenSelector";

const Home: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const user = useSelector(selectUserInstance);
  const { isAuthenticated } = useSelector((state: RootState) => state.user.auth);
  const submissionData = useSelector(fincenSelectors.submissionData);
  const [activeStep, setActiveStep] = useState<number>(0);
  const [boirForm, setBoirForm] = useState<BOIRFormData>(getFormDefaults());
  const [xmlFile, setXmlFile] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);
  const location = useLocation();
  const navigate = useNavigate();

  const initiateSubmission = (data: BOIRFormData): void => {
    setBoirForm(data);
    setActiveStep(2);
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (params.get("reset") === "true") {
      setActiveStep(0);
    }
  }, [location, navigate]);

  useEffect(() => {
    if (activeStep === 0) {
      setBoirForm(getFormDefaults());
      dispatch({ type: "fincen/reset" });
    }

    if (activeStep === 1) {
      dispatch({ type: "fincen/reset" });
    }
  }, [activeStep, dispatch]);

  const handleBoirFormSubmission = async (): Promise<void> => {
    try {
      const result = await dispatch(fetchTokenAndInitiateSubmission());
      if (fetchTokenAndInitiateSubmission.fulfilled.match(result)) {
        const beneficialOwners = boirForm.beneficialOwners ?? [];
        const companyApplicants =
          Number(boirForm.reportingCompany.yearFormed) == 2024
            ? (boirForm.companyApplicants ?? [])
            : [];
        const people = [...beneficialOwners, ...companyApplicants];

        const uploadPromises = people.map(async (person) => {
          if (person.document) {
            const fileName = encodeURIComponent(
              new Date().getTime() + "-" + person.document[0].name
            );
            person.documentFileName = fileName;
            return dispatch(uploadAttachment({ fileName: fileName, fileData: person.document[0] }));
          }
        });

        await Promise.all(uploadPromises);

        const xml = generateBOIRXML(boirForm);
        setXmlFile(xml);

        dispatch(submitForm({ xmlData: xml }));
        setActiveStep(3);
      }
    } catch (error) {
      console.error("Error during submission:", error);
    }
  };

  const getSubmissionDisplayStatus = (
    status: SubmissionStatus
  ): "completed" | "error" | undefined => {
    if (status === SubmissionStatus.ACCEPTED) {
      return "completed";
    }

    if (
      status === SubmissionStatus.REJECTED ||
      status === SubmissionStatus.FAILED ||
      status === SubmissionStatus.VALIDATION_FAILED
    ) {
      return "error";
    }

    return undefined;
  };

  const slides: Slide[] = [
    {
      title: "FinCen Obligations",
      enabled: true,
      content: <Survey setActiveStep={setActiveStep} />,
    },
    {
      title: "BOIR Form",
      enabled: user.hasCompletedSurvey,
      content: isAuthenticated ? (
        <BOIRForm initiateSubmission={initiateSubmission} />
      ) : (
        <Box
          display="flex"
          flexDirection={{ xs: "column", md: "row" }}
          justifyContent="space-between"
          gap={2}
        >
          <Box flex={1}>
            <ProductDescription />
          </Box>
          <Box flex={1}>
            <SignUpSignInSlide />
          </Box>
        </Box>
      ),
    },
    {
      title: "Consent and Form Submission",
      enabled: false,
      content: <FormSubmission onSubmit={handleBoirFormSubmission} />,
    },
    {
      title: "Submission Status",
      enabled: false,
      content: <SubmissionStatusDisplay />,
      status: getSubmissionDisplayStatus(submissionData.status.submissionStatus),
    },
  ];

  useEffect(() => {
    const checkPendingUserVerification = async (): Promise<void> => {
      const uid = localStorage.getItem("pendingUserUid");
      const savedStep = localStorage.getItem("currentStep");

      if (uid && auth.currentUser && auth.currentUser.uid === uid) {
        await auth.currentUser.reload();

        if (auth.currentUser.emailVerified) {
          localStorage.removeItem("pendingUserUid");
          localStorage.setItem("currentStep", "2");
          setActiveStep(2);
        } else {
          setActiveStep(1);
        }
      } else if (savedStep) {
        setActiveStep(parseInt(savedStep));
        localStorage.removeItem("currentStep");
      }
      setLoading(false);
    };

    checkPendingUserVerification();
  }, []);

  return (
    <>
      {loading ? (
        <Box sx={{ textAlign: "center", marginTop: 10 }}>
          <CircularProgress />
        </Box>
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ClickableStepper
              slides={slides}
              activeStep={activeStep}
              setActiveStep={setActiveStep}
            />
          </Grid>
          <Grid item xs={12}>
            {slides[activeStep].content}
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default Home;
