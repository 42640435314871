import { create } from "xmlbuilder2";
import { BOIRFormData, Person } from "../boir-form/BOIRFormDefaults";

const YEAR_FORMED = 2024;
const US_PASSPORT = 39;

export const generateBOIRXML = (data: BOIRFormData): string => {
  const root = create({ version: "1.0", encoding: "UTF-8" }).ele("fc2:EFilingSubmissionXML", {
    "xmlns:fc2": "www.fincen.gov/base",
    "xmlns:xsi": "http://www.w3.org/2001/XMLSchema-instance",
    "xsi:schemaLocation":
      "www.fincen.gov/base https://www.fincen.gov/sites/default/files/schema/base/BOIRSchema.xsd",
    SeqNum: "1",
  });

  root
    .ele("fc2:SubmitterElectronicAddressText")
    .txt(data.submmitterInfo.email)
    .up()
    .ele("fc2:SubmitterEntityIndivdualLastName")
    .txt(data.submmitterInfo.lastName)
    .up()
    .ele("fc2:SubmitterIndivdualFirstName")
    .txt(data.submmitterInfo.firstName)
    .up();

  const activity = root.ele("fc2:Activity", { SeqNum: "2" });

  activity
    .ele("fc2:ApprovalOfficialSignatureDateText")
    .txt(formatDate(data.filingInformation.datePrepared))
    .up();

  if (["correct", "update", "exempt"].includes(data.filingInformation.typeOfFiling)) {
    activity
      .ele("fc2:EFilingPriorReportingCompanyIdentificationNumberText")
      .txt(data.filingInformation.taxIdNumber)
      .up()
      .ele("fc2:EFilingPriorReportingCompanyIdentificationTypeCode")
      .txt(data.filingInformation.taxIdType)
      .up();

    if (data.filingInformation.jurisdiction) {
      activity
        .ele("fc2:EFilingPriorReportingCompanyIssuerCountryCodeText")
        .txt(data.filingInformation.jurisdiction)
        .up();
    }

    activity.ele("fc2:EFilingPriorReportingCompanyName").txt(data.filingInformation.legalName).up();
  }

  activity.ele("fc2:FilingDateText").up();

  const activityAssociation = activity.ele("fc2:ActivityAssociation", { SeqNum: "3" });
  switch (data.filingInformation.typeOfFiling) {
    case "initial":
      activityAssociation.ele("fc2:InitialReportIndicator").txt("Y").up();
      break;
    case "correct":
      activityAssociation.ele("fc2:CorrectsAmendsPriorReportIndicator").txt("Y").up();
      break;
    case "update":
      activityAssociation.ele("fc2:UpdatePriorReportIndicator").txt("Y").up();
      break;
    case "exempt":
      activityAssociation.ele("fc2:ReportingCompanyBecameExemptIndicator").txt("Y").up();
      break;
  }

  if (data.filingInformation.typeOfFiling !== "exempt") {
    addReportingCompany(activity, data);
    if (Number(data.reportingCompany.yearFormed) == YEAR_FORMED) {
      addCompanyApplicants(activity, data.companyApplicants);
    }
    addBeneficialOwners(activity, data.beneficialOwners);
  }

  return root.end({ prettyPrint: true });
};

const formatDate = (date: string): string => {
  const d = new Date(date);
  return (
    d.getFullYear().toString() +
    (d.getMonth() + 1).toString().padStart(2, "0") +
    d.getDate().toString().padStart(2, "0")
  );
};

const addReportingCompany = (activity: any, data: BOIRFormData) => {
  const reportingCompanyParty = activity.ele("fc2:Party", { SeqNum: "4" });

  reportingCompanyParty.ele("fc2:ActivityPartyTypeCode").txt("62").up();

  if (Number(data.reportingCompany.yearFormed) < YEAR_FORMED) {
    reportingCompanyParty.ele("fc2:ExistingReportingCompanyIndicator").txt("Y").up();
  }

  reportingCompanyParty
    .ele("fc2:FormationCountryCodeText")
    .txt(data.reportingCompany.territory)
    .up()
    .ele("fc2:FormationStateCodeText")
    .txt(data.reportingCompany.state)
    .up();

  const partyName = reportingCompanyParty.ele("fc2:PartyName", { SeqNum: "5" });
  partyName
    .ele("fc2:PartyNameTypeCode")
    .txt("L")
    .up()
    .ele("fc2:RawPartyFullName")
    .txt(data.reportingCompany.legalName)
    .up();

  const address = reportingCompanyParty.ele("fc2:Address", { SeqNum: "6" });
  address
    .ele("fc2:RawCityText")
    .txt(data.reportingCompany.city)
    .up()
    .ele("fc2:RawCountryCodeText")
    .txt(data.reportingCompany.country || "US")
    .up()
    .ele("fc2:RawStateCodeText")
    .txt(data.reportingCompany.state)
    .up()
    .ele("fc2:RawStreetAddress1Text")
    .txt(data.reportingCompany.address)
    .up()
    .ele("fc2:RawZIPCode")
    .txt(data.reportingCompany.zipCode)
    .up();

  const identification = reportingCompanyParty.ele("fc2:PartyIdentification", { SeqNum: "7" });
  identification
    .ele("fc2:PartyIdentificationNumberText")
    .txt(data.reportingCompany.taxIdNumber)
    .up()
    .ele("fc2:PartyIdentificationTypeCode")
    .txt(data.reportingCompany.taxIdType)
    .up();
};

const addCompanyApplicants = (activity: any, applicants: Person[]) => {
  applicants.forEach((applicant, index) => {
    const seqBase = 8 + index * 3;
    addParty(activity, applicant, "63", seqBase);
  });
};

const addBeneficialOwners = (activity: any, owners: Person[]) => {
  owners.forEach((owner, index) => {
    const seqBase = 8 + (index + owners.length) * 3 + 1;
    addParty(activity, owner, "64", seqBase);
  });
};

const addParty = (activity: any, person: Person, partyTypeCode: string, seqBase: number) => {
  const party = activity.ele("fc2:Party", { SeqNum: String(seqBase) });
  party.ele("fc2:ActivityPartyTypeCode").txt(partyTypeCode).up();

  if (person.finCENId) {
    party.ele("fc2:FinCENID").txt(person.finCENId).up();
  } else {
    party.ele("fc2:IndividualBirthDateText").txt(formatDate(person.dateOfBirth)).up();

    const partyName = party.ele("fc2:PartyName", { SeqNum: String(seqBase + 1) });
    partyName
      .ele("fc2:PartyNameTypeCode")
      .txt("L")
      .up()
      .ele("fc2:RawEntityIndividualLastName")
      .txt(person.lastName)
      .up()
      .ele("fc2:RawIndividualFirstName")
      .txt(person.firstName)
      .up();

    if (person.middleName) {
      partyName.ele("fc2:RawIndividualMiddleName").txt(person.middleName).up();
    }

    if (person.suffix) {
      partyName.ele("fc2:RawIndividualNameSuffixText").txt(person.suffix).up();
    }

    const address = party.ele("fc2:Address", { SeqNum: String(seqBase + 2) });
    address
      .ele("fc2:RawCityText")
      .txt(person.city)
      .up()
      .ele("fc2:RawCountryCodeText")
      .txt(person.country || "US")
      .up()
      .ele("fc2:RawStateCodeText")
      .txt(person.state)
      .up()
      .ele("fc2:RawStreetAddress1Text")
      .txt(person.address)
      .up()
      .ele("fc2:RawZIPCode")
      .txt(person.zipCode)
      .up()
      .ele("fc2:ResidentialAddressIndicator")
      .txt("Y")
      .up();

    const identification = party.ele("fc2:PartyIdentification", {
      SeqNum: String(seqBase + 3),
    });
    identification
      .ele("fc2:OriginalAttachmentFileName")
      .txt(person.documentFileName)
      .up()
      .ele("fc2:OtherIssuerCountryText")
      .txt(person.documentCountry)
      .up();

    if (person.documentType !== US_PASSPORT) {
      identification.ele("fc2:OtherIssuerStateText").txt(person.documentState).up();
    }

    identification
      .ele("fc2:PartyIdentificationNumberText")
      .txt(person.documentNumber)
      .up()
      .ele("fc2:PartyIdentificationTypeCode")
      .txt(person.documentType)
      .up();
  }
};
