import { Controller, useFormContext } from "react-hook-form";
import {
  Box,
  FormControl,
  FormGroup,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { RequiredFormLabel } from "../../../../components/RequiredFormLabel";
import { BOIRFormStatesComponent } from "./BOIRFormStatesComponent";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { US_TERRITORIES } from "../../../../constants/territories";

export const BOIRFormReportingCompany = () => {
  const {
    control,
    formState: { errors },
    watch,
  } = useFormContext();

  return (
    <Box sx={{ p: 1 }}>
      <FormGroup sx={{ gap: 1 }}>
        <Typography variant="subtitle1">Full legal name and alternate name(s):</Typography>

        <FormControl fullWidth error={!!errors.reportingCompany?.legalName}>
          <Controller
            name="reportingCompany.legalName"
            control={control}
            rules={{
              required: "Legal name is required",
              maxLength: {
                value: 150,
                message: "Legal name must be less than 150 characters",
              },
            }}
            render={({ field }) => (
              <TextField
                {...field}
                label="Reporting Company legal name"
                required
                size="small"
                error={!!errors.reportingCompany?.legalName}
                helperText={errors.reportingCompany?.legalName?.message}
              />
            )}
          />
        </FormControl>

        <Controller
          name="reportingCompany.alternateNames"
          control={control}
          defaultValue={[""]}
          render={({ field: { value, onChange } }) => (
            <Box>
              {value.map((name, index) => (
                <Box key={index} sx={{ display: "flex", gap: 1, mb: 1 }}>
                  <TextField
                    fullWidth
                    size="small"
                    value={name}
                    onChange={(e) => {
                      const newNames = [...value];
                      newNames[index] = e.target.value;
                      onChange(newNames);
                    }}
                    label={`Alternate name ${index + 1} (e.g. Trade Name, "Doing Business As" Name)`}
                  />
                  {value.length > 1 && (
                    <IconButton
                      onClick={() => {
                        const newNames = value.filter((_, i) => i !== index);
                        onChange(newNames);
                      }}
                      size="small"
                    >
                      <DeleteIcon color="primary" />
                    </IconButton>
                  )}
                </Box>
              ))}
              <Tooltip
                title={<Typography variant="body2">Add another alternate name</Typography>}
                placement="top"
              >
                <IconButton
                  onClick={() => onChange([...value, ""])}
                  size="small"
                  sx={{ mt: 1 }}
                  color="primary"
                >
                  <AddCircleOutlineIcon />
                </IconButton>
              </Tooltip>
            </Box>
          )}
        />

        <Typography variant="subtitle1" sx={{ mt: 2 }}>
          Form of identification:
        </Typography>

        <FormControl fullWidth required error={!!errors.reportingCompany?.taxIdType}>
          <Box display="flex" alignItems="center" gap={0.5}>
            <RequiredFormLabel required>Tax Identification type</RequiredFormLabel>
            <Tooltip
              title={
                <Typography variant="body2">
                  Tax identification type: Select &#39;EIN&#39; if the reporting company has a U.S.
                  Employer Identification Number (EIN).
                  <br />
                  Select &#39;SSN-ITIN&#39; if the reporting company utilizes a U.S. Social Security
                  Number (SSN) or Individual Taxpayer Identification Number (ITIN).
                </Typography>
              }
              placement="top"
              arrow
              sx={{ cursor: "pointer" }}
            >
              <InfoOutlinedIcon fontSize="small" />
            </Tooltip>
          </Box>
          <Controller
            name="reportingCompany.taxIdType"
            control={control}
            rules={{ required: "Tax ID type is required" }}
            render={({ field }) => (
              <Select {...field} error={!!errors.reportingCompany?.taxIdType} size="small">
                <MenuItem value="1">SSN/ITIN</MenuItem>
                <MenuItem value="2">EIN</MenuItem>
              </Select>
            )}
          />
          {errors.reportingCompany?.taxIdType ? (
            <FormHelperText>{errors.reportingCompany.taxIdType.message}</FormHelperText>
          ) : (
            <FormHelperText sx={{ color: "text.secondary", fontSize: "0.875rem" }}>
              If you have a Foreign Tax ID, please contact us at:{" "}
              <a href="mailto:info@licenz.com">info@licenz.com</a>
            </FormHelperText>
          )}
        </FormControl>

        <FormControl fullWidth error={!!errors.reportingCompany?.taxIdNumber}>
          <Controller
            name="reportingCompany.taxIdNumber"
            control={control}
            rules={{
              required: "Tax ID number is required",
              pattern: {
                value: /^[0-9-]+$/,
                message: "Please enter a valid tax ID number",
              },
              validate: (value) => {
                if (value.replace(/\D/g, "").length !== 9) {
                  return "Tax ID number must be 9 digits long";
                }
                return true;
              },
            }}
            render={({ field }) => (
              <TextField
                {...field}
                label="Tax Identification number"
                required
                size="small"
                error={!!errors.reportingCompany?.taxIdNumber}
                helperText={errors.reportingCompany?.taxIdNumber?.message}
                inputProps={{
                  maxLength: 9,
                }}
                onChange={(e) => {
                  const value = e.target.value.replace(/\D/g, "");
                  field.onChange(value.slice(0, 9));
                }}
              />
            )}
          />
        </FormControl>

        <Typography variant="subtitle1" sx={{ mt: 2 }}>
          Current U.S. Address (If you have a Foreign Address, please contact us at:{" "}
          <a href="mailto:info@licenz.com">info@licenz.com</a>):
        </Typography>

        <FormControl fullWidth error={!!errors.reportingCompany?.address}>
          <Controller
            name="reportingCompany.address"
            control={control}
            rules={{ required: "Address is required" }}
            render={({ field }) => (
              <TextField
                {...field}
                label="Address (No P.O. Box allowed)"
                placeholder="number, street, and apt. or suite no."
                required
                size="small"
                error={!!errors.reportingCompany?.address}
                helperText={errors.reportingCompany?.address?.message}
              />
            )}
          />
        </FormControl>

        <FormControl fullWidth error={!!errors.reportingCompany?.city}>
          <Controller
            name="reportingCompany.city"
            control={control}
            rules={{ required: "City is required" }}
            render={({ field }) => (
              <TextField
                {...field}
                label="City"
                required
                size="small"
                error={!!errors.reportingCompany?.city}
                helperText={errors.reportingCompany?.city?.message}
              />
            )}
          />
        </FormControl>

        <FormControl fullWidth required error={!!errors.reportingCompany?.territory}>
          <Controller
            name="reportingCompany.territory"
            control={control}
            rules={{ required: "Territory is required" }}
            render={({ field }) => (
              <FormControl required error={!!errors.reportingCompany?.territory} size="small">
                <InputLabel>Territory</InputLabel>
                <Select {...field} label="Territory">
                  {US_TERRITORIES.map((territory) => (
                    <MenuItem key={territory.value} value={territory.value}>
                      {territory.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          />
          {errors.reportingCompany?.territory && (
            <FormHelperText>{errors.reportingCompany.territory.message}</FormHelperText>
          )}
        </FormControl>

        <FormControl
          fullWidth
          required={watch("reportingCompany.territory") === "US"}
          error={!!errors.reportingCompany?.state}
        >
          <Controller
            name="reportingCompany.state"
            control={control}
            rules={{
              required: watch("reportingCompany.territory") === "US" ? "State is required" : false,
            }}
            render={({ field }) => (
              <FormControl
                required={watch("reportingCompany.territory") === "US"}
                error={!!errors.reportingCompany?.state}
                disabled={watch("reportingCompany.territory") !== "US"}
                size="small"
              >
                <InputLabel>State</InputLabel>
                <BOIRFormStatesComponent
                  {...field}
                  error={!!errors.reportingCompany?.state}
                  disabled={watch("reportingCompany.territory") !== "US"}
                  label="State"
                />
              </FormControl>
            )}
          />
          {errors.reportingCompany?.state && (
            <FormHelperText>{errors.reportingCompany.state.message}</FormHelperText>
          )}
        </FormControl>

        <FormControl fullWidth error={!!errors.reportingCompany?.zipCode}>
          <Controller
            name="reportingCompany.zipCode"
            control={control}
            rules={{
              required: "ZIP Code is required",
              pattern: {
                value: /^\d{5}(-\d{4})?$/,
                message: "Please enter a valid ZIP code",
              },
            }}
            render={({ field }) => (
              <TextField
                {...field}
                label="ZIP Code"
                required
                size="small"
                error={!!errors.reportingCompany?.zipCode}
                helperText={errors.reportingCompany?.zipCode?.message}
              />
            )}
          />
        </FormControl>

        <FormControl fullWidth error={!!errors.reportingCompany?.yearFormed}>
          <Controller
            name="reportingCompany.yearFormed"
            control={control}
            rules={{
              required: "Year formed is required",
              pattern: {
                value: /^\d{4}$/,
                message: "Please enter a valid 4-digit year",
              },
            }}
            render={({ field }) => (
              <TextField
                {...field}
                label="Year formed"
                required
                size="small"
                error={!!errors.reportingCompany?.yearFormed}
                helperText={errors.reportingCompany?.yearFormed?.message}
                inputProps={{
                  maxLength: 4,
                  inputMode: "numeric",
                  pattern: "[0-9]*",
                }}
              />
            )}
          />
        </FormControl>
      </FormGroup>
    </Box>
  );
};
