import { isValidFirebaseUid, isValidName, isValidEmail, isValidPhoneNumber } from "./userHelper";

class User {
  constructor(
    firebaseUid,
    hasCompletedSurvey,
    payment_complete,
    firstName,
    lastName,
    email,
    phoneNumber
  ) {
    this.firebaseUid = isValidFirebaseUid(firebaseUid) ? firebaseUid : "";
    this.hasCompletedSurvey = hasCompletedSurvey ?? false;
    this.payment_complete = payment_complete ?? false;
    this.firstName = isValidName(firstName) ? firstName : "";
    this.lastName = isValidName(lastName) ? lastName : "";
    this.email = isValidEmail(email) ? email : "";
    this.phoneNumber = isValidPhoneNumber(phoneNumber) ? phoneNumber : "";
  }

  isValidUser() {
    return (
      isValidFirebaseUid(this.firebaseUid) &&
      isValidName(this.firstName) &&
      isValidName(this.lastName) &&
      isValidEmail(this.email) &&
      isValidPhoneNumber(this.phoneNumber)
    );
  }

  formatForState() {
    return {
      firebaseUid: this.firebaseUid,
      hasCompletedSurvey: this.hasCompletedSurvey,
      payment_complete: this.payment_complete,
      firstName: this.firstName,
      lastName: this.lastName,
      email: this.email,
      phoneNumber: this.phoneNumber,
    };
  }
}

export default User;
