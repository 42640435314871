import * as React from "react";
import { useTheme } from "@mui/material/styles";
import { Typography, Box, Container } from "@mui/material";

const Footer = () => {
  const theme = useTheme();

  return (
    <Box
      component="footer"
      sx={{
        py: 6,
        textAlign: "center",
        backgroundColor: theme.palette.primary.main,
        color: "white",
      }}
    >
      <Container maxWidth="lg">
        <Typography variant="body1">
          Powered by{" "}
          <Box
            component="a"
            href="https://licenz.com" // Link to licenz.com
            target="_blank" // Open in a new tab
            rel="noopener noreferrer" // Security: prevent access to window object
            sx={{ color: "#48a1af", textDecoration: "none" }}
          >
            Licenz
          </Box>
        </Typography>
        {/* Copyright Notice */}
        <Typography variant="body2" sx={{ mt: 1 }}>
          © {new Date().getFullYear()} Licenz, LLC. All rights reserved.
        </Typography>
      </Container>
    </Box>
  );
};

export default Footer;
