export const DEFAULT_MESSAGE =
  "Thanks for finishing the survey. Please continue to register for BOI fililng or retake the survey.";
export const MAX = "MAX";
export const NO = "NO";
export const YES = "YES";

export const questions = [
  {
    question:
      "Has the company in question generated over $5 million in revenue in the past year and does it have over 20 employees?",
    key: "sizeOfCompany",
    yesMessage:
      "Based on your response, your business might not have a reporting obligation due to its substantial operational size in the U.S. If you made a mistake feel free to update your responses below.",
    noMessage: "",
    yesLogic: MAX,
    noLogic: +1,
  },
  {
    question: "Has the company in question engaged in business during the past twelve months?",
    key: "activityOfCompany",
    yesMessage:
      "Based on your response the company meets BOI reporting requirements and must file BOIR to remain compliant please register below.",
    yesLogic: MAX,
    noLogic: +1,
  },
  {
    question: "Is the company in question directly or indirectly owned by a foreign person?",
    key: "foreignOwnership",
    yesMessage:
      "Your company meets BOI reporting requirements due to foreign ownership please register to file below.",
    noMessage: "",
    yesLogic: MAX,
    noLogic: +1,
  },
  {
    question:
      "During the past year, has the company in question experienced a change in ownership or sent or received funds in an amount greater than $1,000?",
    key: "ownershipChange",
    yesMessage:
      "Your company meets BOI reporting requirements due to recent changes in ownership or significant financial transactions please register to file below.",
    noMessage: "",
    yesLogic: MAX,
    noLogic: +1,
  },
  {
    question: "Does the company hold any assets?",
    key: "assetsHeld",
    yesMessage: "Your company meets BOI filing requirements. Please register to file below",
    noMessage:
      "Based on your response, your business might not have a reporting obligation due to its inactive status. If you made a mistake feel free to update your responses below.",
    yesLogic: MAX,
    noLogic: MAX,
  },
];

export const exemptionDetails = [
  {
    title: "Large Operating Company",
    details: [
      "Employs more than 20 employees on a full-time basis in the United States.",
      "Filed Federal income tax returns last year demonstrating more than $5,000,000 in gross receipts or sales.",
      "Has an operating presence at a physical office within the United States.",
    ],
  },
  {
    title: "Inactive Entity",
    details: [
      "In existence for over 1 year without engaging in active business.",
      "Not owned, directly or indirectly, by a foreign person.",
      "Has not experienced a change in ownership nor sent/received funds over $1,000 in the last 12 months.",
      "Does not hold any assets or ownership interests in any entity.",
    ],
  },
];

type Question = {
  question: React.ReactNode;
  key: string;
};

export const boirQuestions: Question[] = [
  {
    question: (
      <div>
        {`The Corporate Transparency Act ("CTA"),`}
        <a
          href="https://www.govregs.com/uscode/title31_subtitleIV_chapter53_subchapterII_section5336"
          target="_blank"
          rel="noopener noreferrer"
        >
          31 U.S.C. § 5336
        </a>
        {`, requires most entities incorporated or doing business under State law to disclose personal stakeholder information (a Beneficial Ownership Information Report or "BOIR") to the Treasury Department's criminal enforcement arm, Financial Crimes Enforcement Network ("FinCEN"). According to Congress, this law is intended to prevent financial crimes such as money laundering and tax evasion committed using shell corporations.`}
      </div>
    ),
    key: "ctaExplanation",
  },
  {
    question:
      "When beginning this process, first and foremost, understand that FinCEN will accept most anything you file.  If you file on your own, you may file something that is “successfully filed” yet incorrect under the most recent regulations – potentially giving rise to fines and penalties.",
    key: "filingOnYourOwn",
  },
  {
    question:
      "Licenz will assist you in all aspects of the BOIR process.  If your company is required to file a BOIR, this process can be made easier with a little planning.",
    key: "licenzAssistance",
  },
  {
    question: (
      <div>
        <p>
          {`  There are three sections to the BOIR – it seeks information on the Reporting Company, its
          Applicant – or the person(s) who directly filed the company's formation documents with the
          State; and its Beneficial Owners. Some important planning steps for your Initial Report
          include:`}
        </p>

        <ul>
          <li>
            {`Confirm the legal name of the company – it must match exactly the name of the company as
            filed with the State. If you have an alternative name or "doing business as" name, that
            must also be listed.`}
          </li>
          <li>
            {`Confirm you are not a `}
            <a
              href="https://www.irs.gov/businesses/small-businesses-self-employed/single-member-limited-liability-companies"
              target="_blank"
              rel="noopener noreferrer"
            >
              disregarded entity for tax reasons
            </a>
            {` – which would mean you use your `}
            <a href="https://www.ssa.gov/number-card" target="_blank" rel="noopener noreferrer">
              SSN
            </a>
            {` as a Tax identification Number rather than using an `}
            <a
              href="https://www.irs.gov/businesses/small-businesses-self-employed/employer-identification-number"
              target="_blank"
              rel="noopener noreferrer"
            >
              EIN
            </a>
            {`. Keep in mind that any `}
            <a
              href="https://www.irs.gov/businesses/small-businesses-self-employed/single-member-limited-liability-companies"
              target="_blank"
              rel="noopener noreferrer"
            >
              {`single member LLC's can be a "disregarded entity" using your SSN in tax filings`}
            </a>
            {`. Keep handy your SSN or EIN, as applicable, when filing your BOIR.`}
          </li>
          <li>
            {`Determine the company's State and year of formation – if the company was formed prior to
            2024, there is need to complete the Applicant section of the BOIR.`}
          </li>
          <li>
            If formed after 2024, determine who was involved in forming the company – was it your
            accountant, lawyer, or online service. At least one – but no more than two, should be
            listed. If they are in the business of forming companies, only their business address is
            required.
          </li>
          <li>
            Determine who owns 25% or more of the company – those persons are Beneficial Owners who
            who must be listed in the BOIR.
          </li>
          <li>
            {`Determine who exercises substantial control over the company – those persons are also
            Beneficial Owners who must be listed in the BOIR. For example, does a spouse enter into
            contracts on behalf of the reporting company; do you outsource the CFO function to
            someone; or do you have any C-Suite officers? All such persons would likely be
            considered Beneficial Owners. Err on the side of caution here and add persons who "may"
            satisfy this exercising of "substantial control" standard given FinCEN would only
            penalize you if you did not list someone and not that you superfluously added a
            "beneficial owner" to the BOIR.`}
          </li>
          <li>
            {`Collect pictures of the unexpired driver's license of every individual who must be
            listed as an Applicant or Beneficial Owner. These IDs will constitute part of the
            information you will need to complete the respective personal information sections.`}
          </li>
          <li>
            Determine a safe location for your downloaded completed report. Keep in mind, unlike any
            other online platform filing BOIRs, Licenz does not retain any sensitive information
            found in the BOIR report. Licenz deploys a unique hardened security approach based on
            decentralized data storage and encryption.
          </li>
          <li>Double check all the data entered in the form!</li>
          <li>
            {`Mistakes can happen and companies can grow over time. That is why you are strongly
            encouraged to locally and securely save your completed Licenz BOIR so that it can be
            easily used to update or correct your filed BOIR using the Licenz platform. If you
            become "newly exempt", i.e., the company grows to more than 20 US employees on a
            full-time basis and filed in the previous year a Federal income tax return demonstrating
            more than $5,000,000 in sales, the completed Licenz BOIR will also be used to file an
            updated report stating the company is "newly exempt".`}
          </li>
          <li>
            {`Keep in mind, after your BOIR filing is completed and you receive a Transcript receipt
            from Licenz, Licenz will also assist you should FinCEN subsequently reject your BOIR or
            seek more information.`}
          </li>
        </ul>
      </div>
    ),
    key: "legalName",
  },
  {
    question: "Now that you are ready, let's get started filing your BOIR!",
    key: "readyToFile",
  },
];
