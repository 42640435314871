import React, { useState } from "react";
import {
  Button,
  Card,
  CardContent,
  Typography,
  LinearProgress,
  Alert,
  Grid,
  Box,
} from "@mui/material";
import { boirQuestions } from "./constants";

type BOIRStepperProps = {
  onComplete: (completed: boolean) => void;
};

export const BoirFilingChecklistStepper: React.FC<BOIRStepperProps> = ({ onComplete }) => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [viewedQuestions, setViewedQuestions] = useState(new Set());

  const handleNext = () => {
    const newViewedQuestions = new Set(viewedQuestions);
    newViewedQuestions.add(currentQuestionIndex);
    setViewedQuestions(newViewedQuestions);

    if (currentQuestionIndex < boirQuestions.length - 1) {
      const nextIndex = currentQuestionIndex + 1;
      setCurrentQuestionIndex(nextIndex);
      if (nextIndex === boirQuestions.length - 1) {
        onComplete(true);
      }
    }
  };

  const handlePrevious = () => {
    setCurrentQuestionIndex(currentQuestionIndex - 1);
  };

  const progress = ((currentQuestionIndex + 1) / boirQuestions.length) * 100;

  return (
    <Card sx={{ width: "100%", mt: 2 }}>
      <CardContent>
        <Alert severity="warning" sx={{ mb: 2 }}>
          You must read carefully and complete the checklist before proceeding to fill out your
          BOIR.
        </Alert>
        <Typography variant="h6" gutterBottom>
          BOIR Filing Requirements Checklist
        </Typography>
        <LinearProgress variant="determinate" value={progress} sx={{ mb: 3 }} />
        <Box>{boirQuestions[currentQuestionIndex].question}</Box>
        <Grid container flexDirection="row" justifyContent="flex-end" gap={1} sx={{ mt: 2 }}>
          <Button variant="outlined" onClick={handlePrevious} disabled={currentQuestionIndex === 0}>
            Previous
          </Button>
          <Button
            variant="contained"
            onClick={handleNext}
            disabled={currentQuestionIndex === boirQuestions.length - 1}
          >
            Next
          </Button>
        </Grid>
      </CardContent>
    </Card>
  );
};
