import { useFieldArray, useFormContext } from "react-hook-form";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  Pagination,
  Stack,
  Typography,
} from "@mui/material";
import { Add as AddIcon, Delete as DeleteIcon } from "@mui/icons-material";
import { BOIRBeneficialOwner } from "./BOIRBeneficialOwner";
import { useState } from "react";

export const BOIRBeneficialOwners = () => {
  const { control } = useFormContext();
  const [currentPage, setCurrentPage] = useState(1);
  const [hasAdditionalOwner, setHasAdditionalOwner] = useState(false);

  const { fields, append, remove } = useFieldArray({
    control,
    name: "beneficialOwners",
  });

  const handleAddBeneficialOwner = () => {
    append({
      finCENId: "",
      firstName: "",
      middleName: "",
      lastName: "",
      suffix: "",
      dateOfBirth: "",
      gender: "",
      addressType: "residential",
      address: "",
      city: "",
      state: "",
      zipCode: "",
      country: "",
      documentType: "",
      documentState: "",
      documentCountry: "US",
      document: null,
    });
    setCurrentPage(fields.length + 1);
  };

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  const handleRemoveBeneficialOwner = (index) => {
    remove(index);
    // Adjust current page if necessary
    if (currentPage > fields.length - 1) {
      setCurrentPage(Math.max(1, fields.length - 1));
    }
  };

  return (
    <Box>
      <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ mb: 2 }}>
        <Typography variant="h6">
          Beneficial Owner {currentPage} of {fields.length}
        </Typography>
        <Box>
          <FormControlLabel
            control={
              <Checkbox
                checked={hasAdditionalOwner}
                onChange={(e) => {
                  setHasAdditionalOwner(e.target.checked);
                  if (!e.target.checked) {
                    // Remove all beneficial owners except the first one
                    for (let i = fields.length - 1; i > 0; i--) {
                      remove(i);
                    }
                    setCurrentPage(1);
                  }
                }}
                size="small"
              />
            }
            label={
              <Typography variant="body2" sx={{ maxWidth: 400 }}>
                I know of another person who may exercise substantial control over this reporting
                company or may own at least 25% of the company
              </Typography>
            }
          />
          {hasAdditionalOwner && (
            <Button
              startIcon={<AddIcon />}
              onClick={handleAddBeneficialOwner}
              variant="contained"
              size="small"
              sx={{ ml: 2 }}
            >
              Add Beneficial Owner
            </Button>
          )}
        </Box>
      </Stack>
      {fields.length > 1 && (
        <Stack direction="row" justifyContent="center" sx={{ mt: 3 }}>
          <Pagination
            count={fields.length}
            page={currentPage}
            onChange={handlePageChange}
            color="primary"
          />
        </Stack>
      )}

      {fields.map((field, index) => (
        <Box
          key={field.id}
          sx={{
            display: currentPage === index + 1 ? "block" : "none",
            position: "relative",
          }}
        >
          <BOIRBeneficialOwner index={index} />
          {fields.length > 1 && (
            <IconButton
              onClick={() => handleRemoveBeneficialOwner(index)}
              sx={{
                position: "absolute",
                top: 0,
                right: 0,
              }}
              color="error"
              size="small"
            >
              <DeleteIcon />
            </IconButton>
          )}
        </Box>
      ))}

      {fields.length > 1 && (
        <Stack direction="row" justifyContent="center" sx={{ mt: 3 }}>
          <Pagination
            count={fields.length}
            page={currentPage}
            onChange={handlePageChange}
            color="primary"
          />
        </Stack>
      )}
    </Box>
  );
};
