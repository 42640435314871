// src/pages/home/components/TermsAndConditions.js

import React, { useState } from "react";
import {
  Box,
  Button,
  Typography,
  Checkbox,
  FormControlLabel,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";

const TermsAndConditions = ({ onAccept }) => {
  const [accepted, setAccepted] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [open, setOpen] = useState(false); // State to control dialog visibility
  const [viewedTerms, setViewedTerms] = useState(false); // Track if Terms of Service has been viewed
  const [viewedPrivacy, setViewedPrivacy] = useState(false); // Track if Privacy Policy has been viewed

  const handleAccept = () => {
    if (accepted) {
      onAccept(); // Proceed with accepting the terms
    } else {
      setShowWarning(true); // Show a warning if not accepted
    }
  };

  const handleDialogOpen = () => {
    setOpen(true);
  };

  const handleDialogClose = () => {
    setOpen(false);
  };

  const handleViewTerms = () => {
    setViewedTerms(true); // Mark Terms of Service as viewed
    handleDialogOpen();
  };

  const handleViewPrivacy = () => {
    setViewedPrivacy(true); // Mark Privacy Policy as viewed
    handleDialogOpen();
  };

  return (
    <Box sx={{ padding: 2, border: "1px solid #ccc", borderRadius: 1 }}>
      <Typography variant="h6" gutterBottom>
        Terms and Conditions
      </Typography>
      <Typography>Please read the following documents before proceeding:</Typography>
      <Box sx={{ marginBottom: 2 }}>
        <Button
          variant="outlined"
          onClick={handleViewTerms} // Open dialog to view terms
          sx={{
            backgroundColor: viewedTerms ? "green" : "white", // Always white background
            color: viewedTerms ? "white" : "black", // Change text color as needed
            "&:hover": { backgroundColor: "lightgreen" }, // Hover effect for better visibility
          }}
        >
          View Terms of Service {viewedTerms && "(Viewed)"}
        </Button>
        <Button
          variant="outlined"
          onClick={handleViewPrivacy} // Open dialog to view privacy policy
          sx={{
            ml: 1,
            backgroundColor: viewedPrivacy ? "green" : "white",
            color: viewedPrivacy ? "white" : "black",
            "&:hover": { backgroundColor: "lightgreen" },
          }}
        >
          View Privacy Policy {viewedPrivacy && "(Viewed)"}
        </Button>
      </Box>
      <FormControlLabel
        control={
          <Checkbox
            checked={accepted}
            onChange={(e) => setAccepted(e.target.checked)}
            disabled={!viewedTerms || !viewedPrivacy}
          />
        }
        label="I accept the Terms and Conditions"
      />
      {showWarning && (
        <Alert severity="warning" sx={{ mt: 2 }}>
          Please accept the terms and conditions before proceeding.
        </Alert>
      )}
      <Button
        variant="contained"
        onClick={handleAccept}
        sx={{ mt: 2 }}
        disabled={!viewedTerms || !viewedPrivacy}
      >
        Continue to Create Account
      </Button>

      {/* Dialog for displaying terms */}
      <Dialog open={open} onClose={handleDialogClose} fullWidth maxWidth="md">
        <DialogTitle>Terms and Conditions</DialogTitle>
        <DialogContent>
          <iframe
            src="/Terms%20of%20Service.docx.pdf" // Path to the PDF file
            style={{ width: "100%", height: "500px", border: "none" }}
            title="Terms and Conditions"
          ></iframe>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default TermsAndConditions;
