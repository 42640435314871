export const US_TERRITORIES = [
  { value: "", label: "Select Territory" },
  { value: "US", label: "United States" },
  { value: "AS", label: "American Samoa" },
  { value: "GU", label: "Guam" },
  { value: "MH", label: "Marshall Islands" },
  { value: "FM", label: "Micronesia, Federated States" },
  { value: "MP", label: "Northern Mariana Islands" },
  { value: "PW", label: "Palau" },
  { value: "PR", label: "Puerto Rico" },
  { value: "VI", label: "Virgin Islands (U.S.)" },
];
