import { createTheme, ThemeOptions } from "@mui/material/styles";

declare module "@mui/material/styles" {
  interface Palette {
    stepper: {
      primary: string;
      light: string;
      gray: string;
    };
  }
  interface PaletteOptions {
    stepper?: {
      primary: string;
      light: string;
      gray: string;
    };
  }
}

const theme = createTheme({
  palette: {
    primary: {
      main: "#01375a",
      light: "#48a1af",
    },
    secondary: {
      main: "#d9d9d9",
    },
    error: {
      main: "#e57373",
    },
    background: {
      default: "#f4f4f4",
      paper: "#ffffff",
    },
    text: {
      primary: "#333333",
      secondary: "#666666",
    },
    stepper: {
      primary: "#784af4",
      light: "#c9b6fa",
      gray: "#eaeaf0",
    },
  },
  typography: {
    fontFamily: "Poppins, Arial, sans-serif",
    h6: {
      fontSize: "1.25rem",
    },
    button: {
      fontWeight: 400,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          borderRadius: 4,
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        asterisk: {
          color: "#e57373", // Using your theme's error color
        },
      },
    },
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        html: {
          overflowX: "hidden",
        },
        body: {
          overflowX: "hidden",
          maxWidth: "100vw",
        },
      },
    },
  },
} as ThemeOptions);

export default theme;
