import React from "react";
import FAQpurple from "./Purple.jpg"; // Import the image

const FaqBanner = () => {
  return (
    <header
      style={{
        backgroundImage: `url(${FAQpurple})`, // Set the image as the background
        backgroundSize: "cover", // Ensure the image covers the entire banner
        backgroundPosition: "center", // Center the image
        backgroundRepeat: "no-repeat", // Prevent the image from repeating
        color: "white",
        textAlign: "center",
        padding: "3rem 0",
        marginLeft: "calc(-50vw + 50%)",
        marginRight: "calc(-50vw + 50%)",
        width: "100vw",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)", // Add a subtle shadow for depth
        position: "relative",
      }}
    >
      {/* Optional Overlay for Readability */}
      <div
        style={{
          backgroundColor: "rgba(0, 0, 0, 0.4)", // Dark overlay for better text contrast
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: 1,
        }}
      />
      <h1
        style={{
          position: "relative",
          zIndex: 2,
          fontFamily: "Georgia, serif",
          fontSize: "2.5rem",
        }}
      >
        Frequently Asked Questions
      </h1>
    </header>
  );
};

export default FaqBanner;
