export const faqs = [
  {
    question: "What is a beneficial ownership information report (BOIR)?",
    answer:
      "A BOIR is a new federal reporting obligation seeking identifying information about individuals who directly or indirectly own or control a company as well as those individuals who applied for the formation of the reporting company. These reports must be filed with the Treasury Department’s enforcement arm, the Financial Crimes Enforcement Network (“FinCEN”).",
  },
  {
    question:
      "Why do small businesses have to report beneficial ownership information to the U.S Department of the Treasury?",
    answer:
      "In 2021, a new beneficial ownership information reporting requirement was made part of the Corporate Transparency Act (CTA). Congress explains that this new report is part of the U.S. government’s efforts to make it harder for criminal actors to hide or benefit from their criminal activity through shell companies and opaque ownership structures.",
  },
  {
    question: "How much effort is required to comply with this new reporting requirement?",
    answer:
      "According to FinCEN’s compliance with the Paperwork Reduction Act of 1995: “The estimated average burden associated with this collection of information from reporting companies is 90 to 650 minutes per respondent for reporting companies with simple or complex beneficial ownership structures, respectively. The estimated average burden associated with reporting companies updating information previously provided is 40 to 170 minutes per respondent for reporting companies with simple or complex beneficial ownership structures, respectively.”",
  },
  {
    question: "What information will a reporting company have to report about itself?",
    answer:
      "A reporting company will have to report: (1) Its legal name; (2) Any trade names, “doing business as” (d/b/a), or “trading as” (t/a) names; (3) The current street address of its principal place of business if that address is in the United States (for example, a U.S. reporting company’s headquarters), or, for reporting companies whose principal place of business is outside the United States, the current address from which the company conducts business in the United States (for example, a foreign reporting company’s U.S. headquarters); (4) Its jurisdiction of formation or registration; and (5) Its Taxpayer Identification Number (or, if a foreign reporting company has not been issued a TIN, a tax identification number issued by a foreign jurisdiction and the name of the jurisdiction). A reporting company will also have to indicate whether it is filing an initial report, or a correction or an update of a prior report.",
  },
  {
    question:
      "What information will a reporting company have to report about its beneficial owners?",
    answer:
      "For everyone who is a beneficial owner, a reporting company will have to provide: (1) The individual’s name; (2) Date of birth; (3) Residential address; and (4) An identifying number from an acceptable identification document such as a passport or U.S. driver’s license, and the name of the issuing state or jurisdiction of the identification document. The reporting company will also have to report an image of the identification document used to obtain the identifying number in item 4.",
  },
  {
    question:
      "Will a reporting company need to report any other information in addition to information about its beneficial owners?",
    answer:
      "Yes. The information that needs to be reported, however, depends on the date when the company was created or registered. If a reporting company is created or registered on or after January 1, 2024, the reporting company will need to report information about itself, its beneficial owners, and its company applicants. If a reporting company was created or registered before January 1, 2024, the reporting company only needs to provide information about itself and its beneficial owners. The reporting company does not need to provide information about its company applicants.",
  },
  {
    question:
      "What information will a reporting company have to report about its company applicants?",
    answer:
      "For each individual who is a company applicant, a reporting company will have to provide: (1) The individual’s name; (2) Date of birth; (3) Address; and (4) An identifying number from an acceptable identification document such as a passport or U.S. driver’s license, and the name of the issuing state or jurisdiction of identification document. The reporting company will also have to report an image of the identification document used to obtain the identifying number in item 4. If the company applicant works in corporate formation—for example, as an attorney or corporate formation agent—then the reporting company must report the company applicant’s business address. Otherwise, the reporting company must report the company applicant’s residential address.",
  },
  {
    question:
      "What are some acceptable forms of identification that will meet the reporting requirement?",
    answer:
      "The only acceptable forms of identification are: (1) A non-expired U.S. driver’s license (including any driver’s licenses issued by a commonwealth, territory, or possession of the United States); (2) A non-expired identification document issued by a U.S. state or local government, or Indian Tribe; (3) A non-expired passport issued by the U.S. government; or (4) A non-expired passport issued by a foreign government (only when an individual does not have one of the other three forms of identification listed above).",
  },
  {
    question:
      "What companies will be required to report beneficial ownership information to FinCEN?",
    answer:
      "There are two types of reporting companies, namely domestic reporting companies which are corporations, limited liability companies, and any other entities formed by the filing of a document with a secretary of state or any similar office in the United States and foreign reporting companies which are entities (including corporations and limited liability companies) formed under the law of a foreign country that have registered to do business in the United States by the filing of a document with a secretary of state or any similar office. There are 23 types of entities that are exempt from these reporting requirements, namely a securities reporting issuer; governmental authority; bank; credit union; depository institution holding company; money services business; broker or dealer in securities; securities exchange or clearing agency; other exchange act registered entity; investment company or investment adviser; venture capital fund adviser; insurance company; state-licensed insurance producer; commodity exchange act registered entity; accounting firm; public utility; financial market utility; pooled investment vehicle; tax-exempt entity; entity assisting a tax-exempt entity; large operating company; subsidiary of certain exempt entities; or inactive entity. If you are an active for-profit small business, it is very likely you are a reporting company required to file a BOIR.",
  },
  {
    question:
      "What are the criteria for the tax-exempt entity exemption from the beneficial ownership information reporting requirement?",
    answer:
      "An entity qualifies for the tax-exempt entity exemption if any of the following four criteria apply: (1) The entity is an organization that is described in section 501(c) of the Internal Revenue Code of 1986 (Code) (determined without regard to section 508(a) of the Code) and exempt from tax under section 501(a) of the Code; (2) The entity is an organization that is described in section 501(c) of the Code, and was exempt from tax under section 501(a) of the Code, but lost its tax-exempt status less than 180 days ago; (3) The entity is a political organization, as defined in section 527(e)(1) of the Code, that is exempt from tax under section 527(a) of the Code; (4) The entity is a trust described in paragraph (1) or (2) of section 4947(a) of the Code.",
  },
  {
    question:
      "Who can file a BOI report on behalf of a reporting company, and what information will be collected on filers?",
    answer:
      "Anyone whom the reporting company authorizes to act on its behalf—such as an employee, owner, or third-party service provider—may file a BOI report on the reporting company’s behalf. When submitting the BOI report, individual filers should be prepared to provide basic contact information about themselves, including their name and email address or phone number.",
  },
  {
    question:
      "Does the list of reporting companies include statutory trusts, business trusts, or foundations?",
    answer:
      "A domestic entity such as a statutory trust, business trust, or foundation is a reporting company only if it was created by the filing of a document with a secretary of state or similar office. Likewise, a foreign entity is a reporting company only if it filed a document with a secretary of state or a similar office to register to do business in the United States. State laws vary on whether certain entity types, such as trusts, require the filing of a document with the secretary of state or similar office to be created or registered. If a trust is created in a U.S. jurisdiction that requires such filing, then it is a reporting company, unless an exemption applies. Similarly, not all states require foreign entities to register by filing a document with a secretary of state or a similar office to do business in the state. If a foreign entity has to file a document with a secretary of state or a similar office to register to do business in a state, and does so, it is a reporting company, unless an exemption applies.",
  },
  {
    question:
      "Does the activity or revenue of a company determine whether it is a reporting company?",
    answer:
      "An entity’s activities and revenue can qualify it for an exemption. For example, there is an exemption for certain inactive entities, and another for any company that reported more than $5 million in gross receipts or sales in the previous year and satisfies other exemption criteria. Engaging solely in passive activities like holding rental properties or being unprofitable does not exempt an entity from the BOI reporting requirements as an inactive entity.",
  },
  {
    question:
      "What are the criteria for the inactive entity exemption from the beneficial ownership information reporting requirement?",
    answer:
      "An entity qualifies for the inactive entity exemption if all six of the following criteria apply: (1) The entity was in existence on or before January 1, 2020; (2) The entity is not engaged in active business; (3) The entity is not owned by a foreign person, whether directly or indirectly, wholly or partially. “Foreign person” means a person who is not a United States person. A United States person is defined in section 7701(a)(30) of the Internal Revenue Code of 1986 as a citizen or resident of the United States, domestic partnership and corporation, and other estates and trusts; (4) The entity has not experienced any change in ownership in the preceding twelve-month period; (5) The entity has not sent or received any funds in an amount greater than $1,000, either directly or through any financial account in which the entity or any affiliate of the entity had an interest, in the preceding twelve-month period; (6) The entity does not otherwise hold any kind or type of assets, whether in the United States or abroad, including any ownership interest in any corporation, limited liability company, or other similar entity.",
  },
  {
    question: "Is a sole proprietorship a reporting company?",
    answer:
      "No, unless a sole proprietorship was created (or, if a foreign sole proprietorship, registered to do business) in the United States by filing a document with a secretary of state or similar office. An entity is a reporting company only if it was created (or, if a foreign company, registered to do business) in the United States by filing such a document. Filing a document with a government agency to obtain (1) an IRS employer identification number, (2) a fictitious business name, or (3) a professional or occupational license does not create a new entity, and therefore does not make a sole proprietorship filing such a document a reporting company.",
  },
  {
    question: "When should my company file a BOIR?",
    answer:
      "FinCEN began accepting beneficial ownership information reports on January 1, 2024. A reporting company created or registered to do business before January 1, 2024, will have until January 1, 2025, to file its initial BOI report. A reporting company created or registered in 2024 will have 90 calendar days to file after receiving actual or public notice that its creation or registration is effective. This 90-calendar day deadline runs from the time the company receives actual notice that its creation or registration is effective, or after a secretary of state or similar office first provides public notice of its creation or registration, whichever is earlier. A reporting company created or registered on or after January 1, 2025, will have 30 calendar days to file after receiving actual or public notice that its creation or registration is effective.",
  },
  {
    question: "Who is a beneficial owner of a reporting company subject to disclosure in a BOIR?",
    answer:
      "A beneficial owner is an individual who either directly or indirectly: (1) exercises substantial control over the reporting company, or (2) owns or controls at least 25% of the reporting company’s ownership interests.",
  },
  {
    question: "What constitutes substantial control sufficient to require reporting?",
    answer:
      "An individual can exercise substantial control over a reporting company in four different ways. If the individual falls into any of the categories below, the individual is exercising substantial control: (1) The individual is a senior officer (the company’s president, chief financial officer, general counsel, chief executive office, chief operating officer, or any other officer who performs a similar function); (2) The individual has authority to appoint or remove certain officers or a majority of directors (or similar body) of the reporting company; (3) The individual is an important decision-maker for the reporting company; (4) The individual has any other form of substantial control over the reporting company.",
  },
  {
    question:
      "One of the indicators of substantial control is that the individual is an important decision-maker. What are important decisions?",
    answer:
      "Important decisions include decisions about a reporting company’s business, finances, and structure. An individual that directs, determines, or has substantial influence over these important decisions exercises substantial control over a reporting company.",
  },
  {
    question: "What is an ownership interest?",
    answer:
      "An ownership interest is generally an arrangement that establishes ownership rights in the reporting company. Examples of ownership interests include shares of equity, stock, voting rights, or any other mechanism used to establish ownership.",
  },
  {
    question: "Who qualifies for an exception from the beneficial owner definition?",
    answer:
      "There are five instances in which an individual who would otherwise be a beneficial owner of a reporting company qualifies for an exception, namely a minor child; nominee, intermediary, custodian or agent; employee; and beneficiary by virtue of a future inheritance. In those cases, the reporting company does not have to report that individual as a beneficial owner to FinCEN.",
  },
  {
    question:
      "What information should a reporting company report about a beneficial owner who holds their ownership interests in the reporting company through multiple exempt entities?",
    answer:
      "If a beneficial owner owns or controls their ownership interests in a reporting company exclusively through multiple exempt entities, then the names of all those exempt entities may be reported to FinCEN instead of the individual beneficial owner’s information. This rule does not apply, however, when an individual owns or controls ownership interests in a reporting company through both exempt and non-exempt entities. In that case, the reporting company must report the individual as a beneficial owner (if no exception applies), but the exempt companies do not need to be listed.",
  },
  {
    question:
      "Is an unaffiliated company that provides a service to the reporting company by managing its day-to-day operations, but does not make decisions on important matters, a beneficial owner of the reporting company?",
    answer:
      "The unaffiliated company itself cannot be a beneficial owner of the reporting company because a beneficial owner must be an individual. Any individuals that exercise substantial control over the reporting company through the unaffiliated company must be reported as beneficial owners of the reporting company. Individuals who do not direct, determine, or have substantial influence over important decisions made by the reporting company, and do not otherwise exercise substantial control, are generally not beneficial owners of the reporting company.",
  },
  {
    question:
      "Is a member of a reporting company’s board of directors always a beneficial owner of the reporting company?",
    answer:
      "No. A beneficial owner of a company is any individual who, directly or indirectly, exercises substantial control over a reporting company, or who owns or controls at least 25 percent of the ownership interests of a reporting company. Whether a particular director meets any of these criteria is a question that the reporting company must consider on a director-by-director basis.",
  },
  {
    question: "Which reporting companies are required to report company applicants?",
    answer:
      "Not all reporting companies have to report their company applicants to FinCEN. A reporting company must report its company applicants only if it is either a domestic reporting company created in the United States on or after January 1, 2024; or a foreign reporting company first registered to do business in the United States on or after January 1, 2024.",
  },
  {
    question: "Who is a company applicant of a reporting company?",
    answer:
      "A company that must report its company applicants will have only up to two individuals who could qualify as company applicants: (1) The individual who directly files the document that creates or registers the company; and (2) If more than one person is involved in the filing, the individual who is primarily responsible for directing or controlling the filing.",
  },
  {
    question: "Is my accountant or lawyer a company applicant?",
    answer:
      "An accountant or lawyer could be a company applicant but only if they had a role in filing the document that creates or registers a reporting company. In many cases, company applicants may work for a business formation service or law firm. An accountant or lawyer may be a company applicant if they directly filed the document that created or registered the reporting company. If more than one person is involved in the filing of the creation or registration document, an accountant or lawyer may be a company applicant if they are primarily responsible for directing or controlling the filing. For example, an attorney at a law firm that offers business formation services may be primarily responsible for overseeing preparation and filing of a reporting company’s incorporation documents. A paralegal at the law firm may directly file the incorporation documents at the attorney’s request. Under those circumstances, the attorney and the paralegal are both company applicants for the reporting company.",
  },
  {
    question:
      "Does a reporting company have to report information about its parent or subsidiary companies?",
    answer:
      "No, though if a special reporting rule applies, the reporting company may report a parent company’s name instead of beneficial ownership information. A reporting company usually must report information about itself, its beneficial owners, and, for reporting companies created or registered on or after January 1, 2024, its company applicants. However, under a special reporting rule, a reporting company may report a parent company’s name in lieu of information about its beneficial owners if its beneficial owners only hold their ownership interest in the reporting company through the parent company and the parent company is an exempt entity.",
  },
  {
    question: "Can a parent company file a single BOI report on behalf of its group of companies?",
    answer:
      "No. Any company that meets the definition of a reporting company and is not exempt is required to file its own BOI report.",
  },
  {
    question: "Can a reporting company report a P.O. box as its current address?",
    answer:
      "No. The reporting company address must be a U.S. street address and cannot be a P.O. box.",
  },
  {
    question:
      "Have I met FinCEN’s BOI reporting obligation if I filed a form or report that provides beneficial ownership information to a state office, a financial institution, or the IRS?",
    answer:
      "No. Reporting companies must report beneficial ownership information directly to FinCEN. Congress enacted a law, the Corporate Transparency Act, that requires the reporting of beneficial ownership information directly to FinCEN. State or local governments, financial institutions, and other federal agencies, such as the IRS, may separately require entities to report certain beneficial ownership information. These requirements are not a substitute for reporting beneficial ownership information to FinCEN.",
  },
  {
    question:
      "Should an initial BOI report include historical beneficial owners of a reporting company, or only beneficial owners as of the time of filing?",
    answer:
      "An initial BOI report should only include the beneficial owners as of the time of the filing. Reporting companies should notify FinCEN of changes to beneficial owners and related BOI through updated reports.",
  },
  {
    question:
      "How does a company created or registered after January 1, 2024, determine its date of creation or registration?",
    answer:
      "The date of creation or registration for a reporting company is the earlier of the date on which: (1) the reporting company receives actual notice that its creation (or registration) has become effective; or (2) a secretary of state or similar office first provides public notice, such as through a publicly accessible registry, that the domestic reporting company has been created or the foreign reporting company has been registered. FinCEN recognizes that there are varying state filing practices. In certain states, automated systems provide notice of creation or registration to newly created or registered companies. In other states, no actual notice of creation or registration is provided, and newly created companies receive notice through the public posting of state records. FinCEN believes that individuals who create or register reporting companies will likely stay apprised of creation or registration notices or publications, given those individuals’ interest in establishing an operating business or engaging in the activity for which the reporting company is created.",
  },
  {
    question: "What should I do if previously reported information changes?",
    answer:
      "If there is any change to the required information about your company or its beneficial owners in a beneficial ownership information report that your company filed, your company must file an updated report no later than 30 days after the date of the change. A reporting company is not required to file an updated report for any changes to previously reported information about a company applicant.",
  },
  {
    question:
      "What are some likely triggers for needing to update a beneficial ownership information report?",
    answer:
      "The following are some examples of the changes that would require an updated beneficial ownership information report: (1) Any change to the information reported for the reporting company, such as registering a new business name; (2) A change in beneficial owners, such as a new CEO, or a sale that changes who meets the ownership interest threshold of 25 percent; (3) Any change to a beneficial owner’s name, address, or unique identifying number previously provided to FinCEN; (4) If a beneficial owner obtained a new driver’s license or other identifying document that includes a changed name, address, or identifying number, the reporting company also would have to file an updated beneficial ownership information report with FinCEN, including an image of the new identifying document.",
  },
  {
    question:
      "Is an updated BOI report required when the type of ownership interest a beneficial owner has in a reporting company changes?",
    answer:
      "No. A change to the type of ownership interest a beneficial owner has in a reporting company—for example, a conversion of preferred shares to common stock—does not require the reporting company to file an updated BOI report because FinCEN does not require companies to report the type of interest. Updated BOI reports are required when information reported to FinCEN about the reporting company or its beneficial owners changes.",
  },
  {
    question:
      "If a reporting company needs to update one piece of information on a BOI report, such as its legal name, does the reporting company have to fill out an entire new BOI report?",
    answer:
      "Updated BOI reports will require all fields to be submitted, including the updated pieces of information. For example, if a reporting company changes its legal name, the reporting company will need to file an updated BOI report to include the new legal name and the previously reported, unchanged information about the company, its beneficial owners, and, if required, its company applicants. A reporting company that filed its prior BOI report using the fillable PDF version may update its saved copy and resubmit to FinCEN. If a reporting company used FinCEN’s web-based application to submit the previous BOI report, it will need to submit a new report in its entirety by either accessing FinCEN’s web-based application to complete and file the BOI report, or by using the PDF option to complete the BOI report and upload to the BOI e-Filing application.",
  },
  {
    question: "Can a filer submit a late updated BOI report?",
    answer:
      "An updated BOI report can be submitted to FinCEN at any time. However, the reporting company is responsible for ensuring that updates are filed within 30 days of a change occurring. If a reporting company has engaged a third-party service provider to file BOI reports and updates on its behalf, then it should communicate any changes to its beneficial ownership information to the third-party service provider with enough time to meet the 30-day deadline.",
  },
  {
    question:
      "If a reporting company last filed a “newly exempt entity” BOI report but subsequently loses its exempt status, what should it do?",
    answer:
      "A reporting company should file an updated BOI report with FinCEN with the company’s current beneficial ownership information when it determines it no longer qualifies for an exemption.",
  },
  {
    question: "What should I do if I learn of an inaccuracy in a report?",
    answer:
      "If a beneficial ownership information report is inaccurate, your company must correct it no later than 30 days after the date your company became aware of the inaccuracy or had reason to know of it. This includes any inaccuracy in the required information provided about your company, its beneficial owners, or its company applicants.",
  },
  {
    question:
      "What should a reporting company do if it becomes exempt after already filing a report?",
    answer:
      "If a reporting company filed a beneficial ownership information report but then becomes exempt from filing the report, the company should file an updated report indicating that it is no longer a reporting company. An updated BOI report for a newly exempt entity will only require that: (1) the entity identify itself; and (2) check a box noting its newly exempt status.",
  },
  {
    question:
      "What happens if a reporting company does not report beneficial ownership information to FinCEN or fails to update or correct the information within the required timeframe?",
    answer:
      "If you correct a mistake or omission within 90 days of the deadline for the original report, you may avoid being penalized. However, you could face civil and criminal penalties if you disregard your beneficial ownership information reporting obligations.",
  },
  {
    question: "What penalties do individuals face for violating BOI reporting requirements?",
    answer:
      "As specified in the CTA, a person who willfully violates the BOI reporting requirements may be subject to civil penalties of up to $500 for each day that the violation continues. That person may also be subject to criminal penalties of up to two years imprisonment and a fine of up to $10,000. Potential violations include willfully failing to file a beneficial ownership information report, willfully filing false beneficial ownership information, or willfully failing to correct or update previously reported beneficial ownership information.",
  },
  {
    question: "Who can be held liable for violating BOI reporting requirements?",
    answer:
      "Both individuals and corporate entities can be held liable for willful violations. This can include not only an individual who files (or attempts to file) false information with FinCEN, but also anyone who willfully provides the filer with false information to report. Both individuals and corporate entities may also be liable for willfully failing to report complete or updated beneficial ownership information; in such circumstances, individuals can be held liable if they either cause the failure or are a senior officer at the company at the time of the failure.",
  },
  {
    question:
      "Can an individual who files a report on behalf of a reporting company be held liable?",
    answer:
      "Yes. An individual who willfully files a false or fraudulent beneficial ownership information report on a company’s behalf may be subject to the same civil and criminal penalties as the reporting company and its senior officers.",
  },
  {
    question:
      "Can a beneficial owner or company applicant be held liable for refusing to provide required information to a reporting company?",
    answer:
      "Yes. As described above, an enforcement action can be brought against an individual who willfully causes a reporting company’s failure to submit complete or updated beneficial ownership information to FinCEN. This would include a beneficial owner or company applicant who willfully fails to provide required information to a reporting company.",
  },
  {
    question:
      "Is a reporting company responsible for ensuring the accuracy of the information that it reports to FinCEN, even if the reporting company obtains that information from another party?",
    answer:
      "Yes. It is the responsibility of the reporting company to identify its beneficial owners and company applicants, and to report those individuals to FinCEN. At the time the filing is made, each reporting company is required to certify that its report or application is true, correct, and complete. Accordingly, FinCEN expects that reporting companies will take care to verify the information they receive from their beneficial owners and company applicants before reporting it to FinCEN.",
  },
  {
    question:
      "What should a reporting company do if a beneficial owner or company applicant withholds information?",
    answer:
      "Starting January 1, 2024, reporting companies will have a legal requirement to report beneficial ownership information to FinCEN. Existing reporting companies should engage with their beneficial owners to advise them of this requirement, obtain required information, and revise or consider putting in place mechanisms to ensure that beneficial owners will keep reporting companies apprised of changes in reported information, if necessary. Beneficial owners and company applicants should also be aware that they may face penalties if they willfully cause a reporting company to fail to report complete or updated beneficial ownership information. Persons considering creating or registering legal entities that will be reporting companies should take steps to ensure that they have access to the beneficial ownership information required to be reported to FinCEN, and that they have mechanisms in place to ensure that the reporting company is kept apprised of changes in that information.",
  },
  {
    question:
      "What are the criteria for the subsidiary exemption from the beneficial ownership information reporting requirement?",
    answer:
      "Subsidiaries of certain types of entities that are exempt from the beneficial ownership information reporting requirements may also be exempt from the reporting requirement. An entity qualifies for the subsidiary exemption if the following applies: The entity’s ownership interests are controlled or wholly owned, directly or indirectly, by any of these types of exempt entities: securities reporting issuer; governmental authority; bank, credit union; depository institution holding company; broker or dealer in securities; securities exchange or clearing agency; other exchange act registered entity; investment company or investment adviser; venture capital fund adviser; insurance company; state-licensed insurance producer; commodity exchange act registered entity; accounting firm; public utility; financial market utility; tax-exempt entity; or large operating company.",
  },
  {
    question:
      "If I own a group of related companies, can I consolidate employees across those companies to meet the criteria of a large operating company exemption from the reporting company definition?",
    answer:
      "No. The large operating company exemption requires that the entity itself employ more than 20 full-time employees in the United States and does not permit consolidation of this employee count across multiple entities.",
  },
  {
    question: "How does a company report to FinCEN that the company is exempt?",
    answer:
      "A company does not need to report to FinCEN that it is exempt from the BOI reporting requirements if it has always been exempted. If a company filed a BOI report and later qualifies for an exemption, that company should file an updated BOI report to indicate that it is newly exempt from the reporting requirements. Updated BOI reports are filed electronically though the secure filing system. An updated BOI report for a newly exempt entity will only require that the entity: (1) identify itself; and (2) check a box noting its newly exempt status.",
  },
  {
    question: "Is there a requirement to annually report beneficial ownership information?",
    answer:
      "No. There is no annual reporting requirement. Reporting companies must file an initial BOI report and updated or corrected BOI reports as needed and nothing more.",
  },
];
