import { useMemo } from "react";
import { api } from "../app/store";
import { useSelector } from "react-redux";

const useApi = () => {
  const { idToken } = useSelector((state) => state.user.auth);

  const get = useMemo(
    () =>
      async (url, params = {}, config = {}) => {
        try {
          const headers = idToken ? { Authorization: `Bearer ${idToken}` } : {};
          const response = await api.get(url, { params, headers, ...config });
          return response.data;
        } catch (error) {
          handleApiError(error);
        }
      },
    [idToken]
  );

  const post = useMemo(
    () =>
      async (url, data, config = {}) => {
        try {
          const headers = idToken ? { Authorization: `Bearer ${idToken}` } : {};
          const response = await api.post(url, data, { headers, ...config });
          return response.data;
        } catch (error) {
          handleApiError(error);
        }
      },
    [idToken]
  );

  const put = useMemo(
    () =>
      async (url, data, config = {}) => {
        try {
          const headers = idToken ? { Authorization: `Bearer ${idToken}` } : {};
          const response = await api.put(url, data, { headers, ...config });
          return response.data;
        } catch (error) {
          handleApiError(error);
        }
      },
    [idToken]
  );

  const del = useMemo(
    () =>
      async (url, config = {}) => {
        try {
          const headers = idToken ? { Authorization: `Bearer ${idToken}` } : {};
          const response = await api.delete(url, { headers, ...config });
          return response.data;
        } catch (error) {
          handleApiError(error);
        }
      },
    [idToken]
  );

  return { get, post, put, del };
};

const handleApiError = (error) => {
  console.error("API error:", error);
  throw error;
};

export default useApi;
