import axios from "axios";
import { logoutUser } from "../features/user/userSlice";

export const createAxiosInstance = (store) => {
  const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_FINSEND_API || "http://127.0.0.1:5000",
    timeout: 10000, // Timeout after 10 seconds
    headers: {
      "Content-Type": "application/json",
    },
  });

  axiosInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
      if (error.response?.status === 401) {
        await store.dispatch(logoutUser());
        window.location.href = `/sign-in?redirect=${window.location.pathname}`;
      }
      return Promise.reject(error);
    }
  );

  return axiosInstance;
};
