import { Outlet } from "react-router-dom";
import { NavigationBar } from "./components/NavigationBar";
import { useLocation } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { HomeBanner } from "./components/HomeBanner";
import FaqBanner from "./components/FaqBanner";
import { Box, CssBaseline, Grid, Container } from "@mui/material";
import Footer from "./components/Footer.js";

const Layout = () => {
  const location = useLocation();
  const theme = useTheme();
  const { pathname } = location;

  // Define the banners to render for different paths
  const banners = [
    {
      pathname: "/",
      content: <HomeBanner />,
    },
    {
      pathname: "/faq",
      content: <FaqBanner />,
    },
  ];

  // Determine if we are on the FAQ page
  const isFAQPage = pathname === "/faq";

  return (
    <Box display="flex" flexDirection="column" minHeight="100vh">
      <CssBaseline />
      <Box component="header" sx={{ pb: "20px" }}>
        <NavigationBar />
        <Grid item xs={12}>
          {banners.map((banner, index) => (
            <div key={index}>{pathname === banner.pathname && banner.content}</div>
          ))}
        </Grid>
      </Box>

      {/* Main content rendering */}
      {isFAQPage ? (
        // Special container for FAQ page
        <Box
          component="main"
          sx={{
            flex: 1,
            padding: "2rem", // Specific padding for FAQ page
            width: "100%", // Full width
            maxWidth: "100%", // Ensure max width is 100% for FAQ
            boxSizing: "border-box",
            margin: "0 auto", // Center content
          }}
        >
          <Outlet />
        </Box>
      ) : (
        // Standard Container for all other pages
        <Container
          component="main"
          sx={{
            flex: 1,
            maxWidth: "lg", // Ensures the width remains standard for non-FAQ pages
            boxSizing: "border-box",
            borderRadius: 1,
            mt: 2,
            mb: 2,
            padding: "0 16px", // Slight padding for other pages
          }}
        >
          <Outlet />
        </Container>
      )}

      <Box
        component="footer"
        sx={{
          backgroundColor: theme.palette.primary.main,
          p: 2,
          mt: "auto",
        }}
      >
        <Footer />
      </Box>
    </Box>
  );
};

export default Layout;
