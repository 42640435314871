import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Menu,
  MenuItem,
  Box,
  IconButton,
  Grid,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { Link } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import PersonIcon from "@mui/icons-material/Person";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logoutUser } from "../../../features/user/userSlice";
import { auth } from "../../../auth/auth";
import { RootState, AppDispatch } from "../../../app/store";

export const NavigationBar = () => {
  const theme = useTheme();
  const { isAuthenticated } = useSelector((state: RootState) => state.user.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const pages = [
    {
      title: "FAQ",
      smallContent: (
        <MenuItem onClick={handleCloseNavMenu}>
          <Typography textAlign="center" onClick={() => navigate("/faq")}>
            FAQ
          </Typography>
        </MenuItem>
      ),
      largeContent: (
        <MenuItem onClick={handleCloseNavMenu}>
          <Typography textAlign="center" onClick={() => navigate("/faq")}>
            FAQ
          </Typography>
        </MenuItem>
      ),
    },

    {
      title: "User",
      smallContent: (
        <>
          {isAuthenticated ? (
            <MenuItem onClick={handleMenuClose} sx={{ marginLeft: "-4px" }}>
              <PersonIcon /> Sign out
            </MenuItem>
          ) : (
            <MenuItem
              onClick={handleMenuClose}
              component={Link}
              sx={{ marginLeft: "-4px" }}
              to="/sign-in"
            >
              <PersonIcon sx={{ marginRight: "2px" }} /> Sign in
            </MenuItem>
          )}
        </>
      ),
      largeContent: (
        <Grid container alignItems="center">
          {isAuthenticated && (
            <Typography variant="body1">Welcome, {auth.currentUser?.displayName}</Typography>
          )}
          <Button
            color="inherit"
            onClick={handleMenuClick}
            sx={{ color: theme.palette.secondary.main, fontSize: "16px" }}
          >
            <PersonIcon />
          </Button>
          <Menu
            id="resources-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleMenuClose}
            MenuListProps={{ "aria-labelledby": "resources-button" }}
            sx={{
              ".MuiPaper-root": {
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.secondary.main,
              },
            }}
          >
            {isAuthenticated ? (
              <MenuItem
                onClick={async () => {
                  handleMenuClose();
                  await dispatch(logoutUser());
                }}
                sx={{ color: theme.palette.secondary.main }}
              >
                Sign out
              </MenuItem>
            ) : (
              <MenuItem
                onClick={handleMenuClose}
                component={Link}
                sx={{ color: theme.palette.secondary.main }}
                to="/sign-in"
              >
                Sign in
              </MenuItem>
            )}
          </Menu>
        </Grid>
      ),
    },
  ];

  return (
    <AppBar
      position="static"
      style={{
        width: "100%",
        backgroundColor: theme.palette.primary.main,
        boxShadow: "none",
      }}
    >
      <Toolbar>
        <Typography
          variant="h6"
          component={Link}
          to="/?reset=true"
          sx={{
            flexGrow: 1,
            color: theme.palette.primary.light,
            fontSize: "18px",
            textDecoration: "none",
            cursor: "pointer",
          }}
        >
          BOIR{" "}
          <span style={{ color: theme.palette.secondary.main, fontSize: "18px" }}>Info Hub</span>
        </Typography>

        <Box
          sx={{
            flexGrow: 1,
            justifyContent: "flex-end",
            display: { xs: "flex", md: "none" },
          }}
        >
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleOpenNavMenu}
            color="inherit"
          >
            <MenuIcon />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorElNav}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            open={Boolean(anchorElNav)}
            onClose={handleCloseNavMenu}
            sx={{
              display: { xs: "block", md: "none" },
              textAlign: "center",
            }}
          >
            {pages.map((page) => (
              <div key={page.title}>{page.smallContent}</div>
            ))}
          </Menu>
        </Box>

        <Box
          sx={{
            flexGrow: 1,
            justifyContent: "flex-end",
            display: { xs: "none", md: "flex" },
          }}
        >
          {pages.map((page) => (
            <div key={page.title}>{page.largeContent}</div>
          ))}
        </Box>
      </Toolbar>
    </AppBar>
  );
};
