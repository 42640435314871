import { Controller, useFormContext } from "react-hook-form";
import {
  Box,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { BOIRFormStatesComponent } from "./BOIRFormStatesComponent";
import { FileDropzone } from "../../../../components/FileDropzone";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { BOIRFormIdentifyingDocumentComponent } from "./BOIRFormDocumentTypeComponent";

export const BOIRBeneficialOwner = ({ index }) => {
  const {
    control,
    formState: { errors },
    watch,
  } = useFormContext();
  const ownerErrors = errors?.beneficialOwners?.[index];

  const fincenId = watch(`beneficialOwners.${index}.finCENId`);
  const hasFinCENId = !!fincenId;

  return (
    <Box sx={{ p: 1 }}>
      <Box display="flex" alignItems="center" gap={0.5} sx={{ mb: 2 }}>
        <Typography variant="subtitle1">Company Applicant FinCEN ID:</Typography>
        <Tooltip
          title={
            <Typography variant="body2">
              Reporting companies may report the FinCEN ID for a beneficial owner instead of the
              information.
              <br />
              Enter the FinCEN ID as a single text string.
              <br />
              If a FinCEN ID for the beneficial owner is not provided, information about the
              beneficial owner must be provided.
            </Typography>
          }
          placement="top"
          arrow
          sx={{ cursor: "pointer" }}
        >
          <InfoOutlinedIcon fontSize="small" />
        </Tooltip>
      </Box>

      <FormGroup sx={{ gap: 1 }}>
        <Controller
          name={`beneficialOwners.${index}.finCENId`}
          control={control}
          render={({ field }) => <TextField {...field} size="small" label="FinCEN ID" />}
        />

        <Typography variant="subtitle1" sx={{ mt: 2 }}>
          Full legal name and date of birth:
        </Typography>

        <Controller
          name={`beneficialOwners.${index}.lastName`}
          control={control}
          rules={{ required: !hasFinCENId && "Last name is required" }}
          render={({ field }) => (
            <TextField
              {...field}
              required={!hasFinCENId}
              disabled={hasFinCENId}
              size="small"
              label="Last name"
              error={!hasFinCENId && !!ownerErrors?.lastName}
              helperText={!hasFinCENId && ownerErrors?.lastName?.message}
            />
          )}
        />

        <Controller
          name={`beneficialOwners.${index}.firstName`}
          control={control}
          rules={{ required: !hasFinCENId && "First name is required" }}
          render={({ field }) => (
            <TextField
              {...field}
              required={!hasFinCENId}
              disabled={hasFinCENId}
              size="small"
              label="First name"
              error={!hasFinCENId && !!ownerErrors?.firstName}
              helperText={!hasFinCENId && ownerErrors?.firstName?.message}
            />
          )}
        />

        <Controller
          name={`beneficialOwners.${index}.middleName`}
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              disabled={hasFinCENId}
              size="small"
              label="Middle name"
              error={!hasFinCENId && !!ownerErrors?.middleName}
              helperText={!hasFinCENId && ownerErrors?.middleName?.message}
            />
          )}
        />

        <Controller
          name={`beneficialOwners.${index}.suffix`}
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              disabled={hasFinCENId}
              size="small"
              label="Suffix"
              error={!hasFinCENId && !!ownerErrors?.suffix}
              helperText={!hasFinCENId && ownerErrors?.suffix?.message}
            />
          )}
        />

        <Controller
          name={`beneficialOwners.${index}.dateOfBirth`}
          control={control}
          rules={{ required: !hasFinCENId && "Date of birth is required" }}
          render={({ field }) => (
            <TextField
              {...field}
              required={!hasFinCENId}
              disabled={hasFinCENId}
              size="small"
              label="Date of birth"
              type="date"
              InputLabelProps={{ shrink: true }}
              error={!!ownerErrors?.dateOfBirth}
              helperText={ownerErrors?.dateOfBirth?.message}
            />
          )}
        />

        <Typography variant="subtitle1" sx={{ mt: 2 }}>
          Current address:
        </Typography>

        <Controller
          name={`beneficialOwners.${index}.addressType`}
          control={control}
          rules={{ required: !hasFinCENId && "Address type is required" }}
          render={({ field }) => (
            <FormControl
              required={!hasFinCENId}
              error={!!ownerErrors?.addressType}
              disabled={hasFinCENId}
            >
              <RadioGroup {...field} row label="Address type">
                <FormControlLabel
                  value="residential"
                  control={<Radio size="small" />}
                  label="Residential address"
                />
                <FormControlLabel
                  value="business"
                  control={<Radio size="small" />}
                  label="Business address"
                />
              </RadioGroup>
            </FormControl>
          )}
        />

        <Controller
          name={`beneficialOwners.${index}.address`}
          control={control}
          rules={{ required: !hasFinCENId && "Address is required" }}
          render={({ field }) => (
            <TextField
              {...field}
              required={!hasFinCENId}
              disabled={hasFinCENId}
              size="small"
              label="Address (no P.O. Box allowed)"
              placeholder="number, street, and apt. or suite no."
              error={!hasFinCENId && !!ownerErrors?.address}
              helperText={!hasFinCENId && ownerErrors?.address?.message}
            />
          )}
        />

        <Controller
          name={`beneficialOwners.${index}.city`}
          control={control}
          rules={{ required: !hasFinCENId && "City is required" }}
          render={({ field }) => (
            <TextField
              {...field}
              required={!hasFinCENId}
              disabled={hasFinCENId}
              size="small"
              label="City"
              error={!hasFinCENId && !!ownerErrors?.city}
              helperText={!hasFinCENId && ownerErrors?.city?.message}
            />
          )}
        />

        <Controller
          name={`beneficialOwners.${index}.state`}
          control={control}
          rules={{ required: !hasFinCENId && "State is required" }}
          render={({ field }) => (
            <FormControl
              required={!hasFinCENId}
              error={!hasFinCENId && !!ownerErrors?.state}
              disabled={hasFinCENId}
              size="small"
            >
              <InputLabel>State</InputLabel>
              <BOIRFormStatesComponent
                {...field}
                error={!hasFinCENId && !!ownerErrors?.state}
                label="State"
              />
            </FormControl>
          )}
        />

        <Controller
          name={`beneficialOwners.${index}.zipCode`}
          control={control}
          rules={{ required: !hasFinCENId && "ZIP code is required" }}
          render={({ field }) => (
            <TextField
              {...field}
              disabled={hasFinCENId}
              required={!hasFinCENId}
              size="small"
              label="ZIP Code"
              error={!hasFinCENId && !!ownerErrors?.zipCode}
              helperText={!hasFinCENId && ownerErrors?.zipCode?.message}
            />
          )}
        />

        <Typography variant="subtitle1" sx={{ mt: 2 }}>
          Form of identification and issuing jurisdiction:
        </Typography>

        <Controller
          name={`beneficialOwners.${index}.documentType`}
          control={control}
          rules={{ required: !hasFinCENId && "Document type is required" }}
          render={({ field }) => (
            <FormControl
              required={!hasFinCENId}
              error={!hasFinCENId && !!ownerErrors?.documentType}
              disabled={hasFinCENId}
              size="small"
            >
              <InputLabel>Identifying document type</InputLabel>
              <BOIRFormIdentifyingDocumentComponent {...field} label="Identifying document type" />
            </FormControl>
          )}
        />

        <Controller
          name={`beneficialOwners.${index}.documentNumber`}
          control={control}
          rules={{
            required: !hasFinCENId && "Document number is required",
            maxLength: !hasFinCENId && {
              value: 25,
              message: "Document number must be 25 characters or less",
            },
          }}
          render={({ field }) => (
            <TextField
              {...field}
              required={!hasFinCENId}
              disabled={hasFinCENId}
              size="small"
              label="Identifying document number"
              error={!hasFinCENId && !!ownerErrors?.documentNumber}
              helperText={!hasFinCENId && ownerErrors?.documentNumber?.message}
            />
          )}
        />

        <Controller
          name={`beneficialOwners.${index}.documentCountry`}
          control={control}
          rules={{ required: !hasFinCENId && "Document country is required" }}
          render={({ field }) => (
            <FormControl
              required={!hasFinCENId}
              size="small"
              error={!hasFinCENId && !!ownerErrors?.documentCountry}
            >
              <InputLabel>Country/Jurisdiction</InputLabel>
              <Select
                {...field}
                value={field.value || ""}
                label="Country/Jurisdiction"
                disabled={hasFinCENId}
              >
                <MenuItem value="US">United States</MenuItem>
              </Select>
              {ownerErrors?.documentCountry && (
                <FormHelperText>{ownerErrors.documentCountry.message}</FormHelperText>
              )}
            </FormControl>
          )}
        />

        <Controller
          name={`beneficialOwners.${index}.documentState`}
          control={control}
          rules={{ required: !hasFinCENId && "Document state is required" }}
          render={({ field }) => (
            <FormControl
              required={!hasFinCENId}
              error={!hasFinCENId && !!ownerErrors?.state}
              disabled={hasFinCENId}
              size="small"
            >
              <InputLabel>State</InputLabel>
              <BOIRFormStatesComponent
                {...field}
                error={!hasFinCENId && !!ownerErrors?.state}
                size="small"
                label="State"
              />
            </FormControl>
          )}
        />

        <Controller
          name={`beneficialOwners.${index}.document`}
          control={control}
          rules={{ required: !hasFinCENId && "Document is required" }}
          render={({ field }) => (
            <FormControl
              required={!hasFinCENId}
              size="small"
              error={!hasFinCENId && !!ownerErrors?.document}
            >
              <InputLabel>Identifying document image</InputLabel>
              <FileDropzone onFileChange={field.onChange} />
              {ownerErrors?.document && (
                <FormHelperText>{ownerErrors.document.message}</FormHelperText>
              )}
            </FormControl>
          )}
        />
      </FormGroup>
    </Box>
  );
};
