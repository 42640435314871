import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "./rootReducer";
import { createAxiosInstance } from "../api/axiosInstance";

export const store = configureStore({
  reducer: rootReducer,
});

export const api = createAxiosInstance(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
