import React, { useState, useEffect } from "react";
import { Box, Button, Typography, Paper, CircularProgress, Alert } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import Checkout from "./home/components/Checkout"; // Correct path for Checkout component
import { collection, query, where, getDocs, doc, setDoc, getDoc } from "firebase/firestore"; // Firestore methods
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage"; // Firebase Storage methods
import { db, auth } from "../auth/auth"; // Correct path to your Firebase setup

const Dashboard = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const [uploadComplete, setUploadComplete] = useState(false); // Track upload status
  const [paymentComplete, setPaymentComplete] = useState(false); // Track payment status
  const [loading, setLoading] = useState(true); // Loading state for Firestore check
  const [uploading, setUploading] = useState(false); // Loading state for file upload
  const [error, setError] = useState(null);

  const storage = getStorage(); // Initialize Firebase Storage

  useEffect(() => {
    const checkPaymentAndUploadStatus = async () => {
      try {
        const firebaseUser = auth.currentUser;
        if (firebaseUser) {
          const paymentQuery = query(
            collection(db, "payments"),
            where("userId", "==", firebaseUser.uid)
          );
          const paymentSnap = await getDocs(paymentQuery);

          if (!paymentSnap.empty) {
            paymentSnap.forEach((doc) => {
              const paymentData = doc.data();
              if (paymentData.status === "Pending" || paymentData.status === "Completed") {
                setPaymentComplete(true);
              }
            });
          }

          const boirDoc = await getDoc(doc(db, "boir_reports", firebaseUser.uid));
          if (boirDoc.exists()) {
            setUploadComplete(true); // Mark upload as complete if found
          }
        }
      } catch (error) {
        console.error("Error checking payment and upload status:", error);
      }
      setLoading(false); // Stop loading once statuses are checked
    };

    checkPaymentAndUploadStatus();
  }, []);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    setFileName(file ? file.name : "");
    setUploadComplete(false); // Reset upload state when a new file is selected
  };

  const handleFileUpload = async () => {
    if (!selectedFile || !paymentComplete) {
      setError("You need to complete the payment and select a file before uploading.");
      return;
    }
    setUploading(true);
    setError(null);

    try {
      const firebaseUser = auth.currentUser;
      if (!firebaseUser) {
        setError("User not authenticated.");
        setUploading(false);
        return;
      }

      const storageRef = ref(
        storage,
        `boir_reports/${firebaseUser.email.replace(/[@.]/g, "-")}/${selectedFile.name}`
      );
      const uploadResult = await uploadBytes(storageRef, selectedFile);

      const downloadURL = await getDownloadURL(uploadResult.ref);

      const uploadData = {
        userId: firebaseUser.uid,
        fileName: selectedFile.name,
        fileURL: downloadURL,
        timestamp: new Date(),
        status: "Uploaded",
      };

      await setDoc(doc(db, "boir_reports", firebaseUser.uid), uploadData);

      setUploadComplete(true); // Mark upload as complete
      setFileName(""); // Reset file input
    } catch (error) {
      console.error("File upload error:", error);
      setError("File upload failed, please try again.");
    } finally {
      setUploading(false);
    }
  };

  return (
    <Box sx={{ display: "flex", padding: 4, gap: 4, position: "relative" }}>
      {/* Contact Information Section Positioned to the Right */}
      <Box
        sx={{
          position: "absolute",
          top: -17,
          right: 20,
          backgroundColor: "lightyellow",
          padding: 1,
          borderRadius: 2,
          boxShadow: 2,
          display: "flex",
          alignItems: "center",
          textAlign: "center",
          border: "1px solid #ddd",
          zIndex: 1000,
        }}
      >
        <Typography variant="body2" sx={{ mr: 1, fontWeight: "bold" }}>
          Questions?
        </Typography>

        <Box sx={{ display: "flex", alignItems: "center", mx: 1 }}>
          <PhoneIcon sx={{ color: "#2196f3", fontSize: "1rem", mr: 0.5 }} />
          <Typography variant="body2" color="textPrimary">
            (973) 255-5008
          </Typography>
        </Box>

        <Box sx={{ display: "flex", alignItems: "center", mx: 1 }}>
          <EmailIcon sx={{ color: "#2196f3", fontSize: "1rem", mr: 0.5 }} />
          <Typography
            variant="body2"
            component="a"
            href="mailto:info@licenz.com"
            sx={{
              color: "#2196f3",
              textDecoration: "none",
              fontWeight: "bold",
              "&:hover": {
                textDecoration: "underline",
              },
            }}
          >
            info@licenz.com
          </Typography>
        </Box>
      </Box>

      {/* Existing dashboard content here */}
      <Box sx={{ flex: 2, display: "flex", flexDirection: "column", gap: 4 }}>
        {/* Payment Section */}
        <Paper elevation={3} sx={{ padding: 4, borderRadius: 2 }}>
          {loading ? (
            <Box sx={{ display: "flex", justifyContent: "center", marginTop: 2 }}>
              <CircularProgress />
            </Box>
          ) : paymentComplete ? (
            <>
              <Typography variant="h6" gutterBottom>
                Thank you for your Payment!
              </Typography>
              <Typography variant="body1" paragraph>
                Please submit your completed BOIR report for review.
              </Typography>
            </>
          ) : (
            <>
              <Typography variant="h6" gutterBottom>
                Payment Section
              </Typography>
              <Typography variant="body1" paragraph>
                Complete your BOIR Filing today. Please submit payment to upload your completed BOIR
                report for final review.
              </Typography>
              <Checkout setPaymentComplete={setPaymentComplete} />
            </>
          )}
        </Paper>

        {/* BOIR Report Upload Section */}
        <Paper elevation={3} sx={{ padding: 4, borderRadius: 2 }}>
          <Typography variant="h6" gutterBottom>
            {uploadComplete
              ? "Thank you for Uploading Your Completed BOIR!"
              : "Upload Your Completed BOIR Report"}
          </Typography>
          <Typography variant="body1" paragraph>
            {uploadComplete
              ? "You will be updated on the status of your filing via email."
              : "You can upload your completed Beneficial Ownership Information Report (BOIR) in PDF format below."}
          </Typography>

          {!uploadComplete && (
            <>
              <Box display="flex" flexDirection="column" sx={{ marginTop: 2 }}>
                <Button
                  variant="contained"
                  component="label"
                  startIcon={<CloudUploadIcon />}
                  sx={{ marginBottom: 2 }}
                >
                  Select File
                  <input type="file" accept=".pdf" hidden onChange={handleFileChange} />
                </Button>

                {fileName && <Typography variant="body1">Selected File: {fileName}</Typography>}

                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleFileUpload}
                  disabled={!selectedFile || !paymentComplete || uploading}
                  sx={{ marginTop: 2 }}
                >
                  {uploading ? "Uploading..." : "Upload BOIR Report"}
                </Button>
              </Box>
              {error && (
                <Alert severity="error" sx={{ marginTop: 2 }}>
                  {error}
                </Alert>
              )}
            </>
          )}
        </Paper>
      </Box>
    </Box>
  );
};

export default Dashboard;
