import React from "react";
import { Box, Typography, List, ListItem, ListItemText } from "@mui/material";

export const ProductDescription = () => {
  return (
    <Box
      sx={{
        padding: 4,
        backgroundColor: "#f5f5f5",
        borderRadius: "8px",
        boxShadow: 1,
      }}
    >
      <Typography variant="h4" component="h2" gutterBottom>
        FinCEN BOIR Filing Service
      </Typography>

      <Typography variant="body1" paragraph>
        Licenz, LLC provides a custom FinCEN BOI report filing service that ensures all sensitive
        data resides where it belongs – with filers. The filing process easily enters a reporting
        company’s data in our secure online system. Here’s how it works:
      </Typography>

      <List sx={{ ml: 4 }}>
        <ListItem>
          <ListItemText primary="✔️ The reporting company's contact person enters the beneficial owner information into a secure PDF document, which Licenz will file with FinCEN on its behalf." />
        </ListItem>
        <ListItem>
          <ListItemText primary="✔️ Once all of the info is reviewed and approved by the filer, we digitally upload the data to FinCEN." />
        </ListItem>
        <ListItem>
          <ListItemText primary="✔️ We then provide the official receipt to the proper contact person." />
        </ListItem>
        <ListItem>
          <ListItemText primary="✔️ At no point in time does Licenz retain sensitive data in any server environment that might put this highly-confidential information at risk." />
        </ListItem>
      </List>
    </Box>
  );
};
