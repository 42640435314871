import React, { useState } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  TextField,
  Button,
  Box,
  Alert,
  Typography,
  Stack,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { loginUser } from "../../features/user/userSlice";
import { useNavigate, useLocation } from "react-router-dom"; // Import useNavigate and useLocation

export const SignIn = () => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [signInEmailPasswordNotice, setSignInEmailPasswordNotice] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const redirectPath = new URLSearchParams(location.search).get("redirect") || "/";

  const handleSignIn = async () => {
    try {
      setSignInEmailPasswordNotice("");
      if (!email || !password) {
        setSignInEmailPasswordNotice("Please enter both email and password");
        return;
      }
      await dispatch(loginUser({ email, password })).unwrap();
      navigate(redirectPath);
    } catch (error) {
      setSignInEmailPasswordNotice(
        error === "auth/network-request-failed"
          ? "Network error. Please check your connection and try again."
          : error
      );
    }
  };

  return (
    <Stack
      direction={{ xs: "column", sm: "row" }}
      alignItems="flex-start"
      justifyContent="center"
      spacing={2}
    >
      <Card>
        <CardHeader title={"Sign In"} />
        <CardContent>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "300px",
              margin: "auto",
            }}
          >
            <TextField
              label="Email"
              variant="outlined"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              sx={{ marginBottom: 2 }}
            />

            <TextField
              label="Password"
              type="password"
              variant="outlined"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              sx={{ marginBottom: 2 }}
            />
            {signInEmailPasswordNotice && (
              <Alert severity="warning" sx={{ marginBottom: 2 }}>
                {signInEmailPasswordNotice}
              </Alert>
            )}
            <Button variant="contained" onClick={handleSignIn} sx={{ marginBottom: 2 }}>
              Sign In with Email
            </Button>
            <Button onClick={() => navigate("/reset-password")} sx={{ marginBottom: 2 }}>
              Forgot Password?
            </Button>
          </Box>

          <Typography>
            Don&#39;t have an account? <Button onClick={() => navigate("/sign-up")}>Sign up</Button>
          </Typography>
        </CardContent>
      </Card>
    </Stack>
  );
};
