import { combineReducers } from "@reduxjs/toolkit";
import userReducer from "../features/user/userSlice";
import fincenReducer from "../features/fincenData/fincenSlice";

const rootReducer = combineReducers({
  user: userReducer,
  fincen: fincenReducer,
});

export default rootReducer;
