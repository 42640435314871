import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { auth } from "../auth/auth";
import { onAuthStateChanged } from "firebase/auth";
import { initializeAuth } from "../features/user/userSlice";

export const useAuthStateListener = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(initializeAuth());

    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        try {
          const token = await user.getIdToken();
          dispatch({
            type: "user/updateAuthState",
            payload: {
              isAuthenticated: user.emailVerified,
              emailVerified: user.emailVerified,
              idToken: token,
              error: null,
            },
          });
        } catch (error) {
          dispatch({
            type: "user/updateAuthState",
            payload: {
              isAuthenticated: false,
              emailVerified: false,
              idToken: null,
              error: error.message,
            },
          });
        }
      } else {
        dispatch({
          type: "user/updateAuthState",
          payload: {
            isAuthenticated: false,
            emailVerified: false,
            idToken: null,
            error: null,
          },
        });
      }
    });

    return () => unsubscribe();
  }, [dispatch]);
};
