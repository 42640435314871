// Checkout.js

import React, { useState } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { Box, Button, Card, CardContent, Alert } from "@mui/material";
import { doc, setDoc } from "firebase/firestore";
import { db, auth } from "../../../auth/auth";

const Checkout = ({ setPaymentComplete }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setError(null);

    if (!stripe || !elements) {
      setError("Stripe.js has not loaded properly.");
      setLoading(false);
      return;
    }

    const cardElement = elements.getElement(CardElement);

    try {
      // Get the Firebase ID token
      const firebaseUser = auth.currentUser;
      if (!firebaseUser) {
        setError("User not authenticated.");
        setLoading(false);
        return;
      }
      const idToken = await firebaseUser.getIdToken();

      // Step 1: Call Firebase Function to Create Payment Intent
      const amount = 50; // $120.00 in cents
      const response = await fetch(
        `${process.env.REACT_APP_FINSEND_API}/stripe/createPaymentIntent`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${idToken}`, // Include the ID token
          },
          body: JSON.stringify({ amount }),
        }
      );

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Failed to create payment intent: ${errorText}`);
      }

      const { clientSecret } = await response.json();
      console.log("Client Secret:", clientSecret); // Log for debugging

      // Step 2: Confirm the Payment Using Stripe
      const result = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: cardElement,
        },
      });

      if (result.error) {
        setError(result.error.message);
        console.error("Payment confirmation error:", result.error);
      } else if (result.paymentIntent && result.paymentIntent.status === "succeeded") {
        console.log("Payment succeeded!");

        // Step 3: Store Payment Information in Firestore
        const paymentData = {
          userId: firebaseUser.uid,
          email: firebaseUser.email,
          paymentIntentId: result.paymentIntent.id,
          amount: amount,
          currency: "usd",
          timestamp: new Date(),
          status: "Succeeded",
        };

        // Store payment information in Firestore under a "payments" collection
        await setDoc(doc(db, "payments", result.paymentIntent.id), paymentData);

        // Update the UI after successful payment
        setPaymentComplete(true);
        setSuccessMessage("Payment successful!");
      }
    } catch (err) {
      console.error("Payment error:", err);
      setError(`Payment failed: ${err.message}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Card>
      <CardContent>
        <form onSubmit={handleSubmit}>
          <Box sx={{ paddingBottom: "20px", width: "100%" }}>
            <CardElement
              options={{
                style: {
                  base: {
                    fontSize: "16px",
                    color: "#424770",
                    "::placeholder": { color: "#aab7c4" },
                  },
                  invalid: { color: "#9e2146" },
                },
              }}
            />
          </Box>
          <Box sx={{ textAlign: "center" }}>
            <Button
              type="submit"
              variant="contained"
              disabled={!stripe || !elements || loading}
              sx={{ width: "100%" }}
            >
              {loading ? "Processing..." : "Pay"}
            </Button>
          </Box>
          {error && (
            <Alert severity="error" sx={{ marginTop: 2 }}>
              {error}
            </Alert>
          )}
          {successMessage && (
            <Alert severity="success" sx={{ marginTop: 2 }}>
              {successMessage}
            </Alert>
          )}
        </form>
      </CardContent>
    </Card>
  );
};

export default Checkout;
