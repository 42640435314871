import React from "react";
import { Box, Button, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { questions, NO, YES } from "./constants";

export type SelectedOption = typeof NO | typeof YES;

type QuestionProps = {
  question: (typeof questions)[number];
  handleResponse: (
    option: SelectedOption,
    question: (typeof questions)[number],
    logic: number | string
  ) => void;
  selectedOption: SelectedOption;
};

const Question: React.FC<QuestionProps> = ({ question, handleResponse, selectedOption }) => {
  const theme = useTheme();

  return (
    <>
      <Typography paragraph>{question.question}</Typography>
      <Box
        sx={{
          mb: 2,
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Button
          variant="outlined"
          sx={{
            color: selectedOption === YES ? theme.palette.primary.main : undefined,
            borderColor: selectedOption === YES ? theme.palette.primary.main : undefined,
          }}
          onClick={() => handleResponse(YES, question, question.yesLogic)}
        >
          Yes
        </Button>
        <Button
          variant="outlined"
          sx={{
            color: selectedOption === NO ? theme.palette.primary.main : undefined,
            borderColor: selectedOption === NO ? theme.palette.primary.main : undefined,
          }}
          onClick={() => handleResponse(NO, question, question.noLogic)}
        >
          No
        </Button>
      </Box>
    </>
  );
};

export default Question;
