const STRIPE_PRICE_PREFIX = "REACT_APP_STRIPE_PRODUCT_PRICE_ID_";

// Create a map of all environment variables starting with STRIPE_PRICE_PRODUCT_ID_
export const stripePrices = Object.entries(process.env)
  .filter(([key]) => key.startsWith(STRIPE_PRICE_PREFIX))
  .reduce((acc, [key, value]) => {
    // Convert STRIPE_PRICE_PRODUCT_ID_BASIC to 'basic'
    const priceKey = key.replace(STRIPE_PRICE_PREFIX, "").toLowerCase();

    return {
      ...acc,
      [priceKey]: value,
    };
  }, {});
