class FinCEN {
  constructor(accessToken, scope, tokenType, expiresIn, processId) {
    this.accessToken = accessToken;
    this.scope = scope;
    this.tokenType = tokenType;
    this.expiresIn = expiresIn;
    this.processId = processId;
  }

  formatForState() {
    return {
      accessToken: this.accessToken,
      scope: this.scope,
      tokenType: this.tokenType,
      expiresIn: this.expiresIn,
      processId: this.processId,
    };
  }
}

export default FinCEN;
