import React, { useState } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardContent,
  Typography,
  LinearProgress,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Stack,
  Box,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { DEFAULT_MESSAGE, questions, NO, YES, MAX } from "./components/constants";
import { useDispatch, useSelector } from "react-redux";
import { selectUserInstance } from "../../../../features/user/userSelector";
import { updateUser } from "../../../../features/user/userSlice";
import Question from "./components/Question";
import { Link } from "react-router-dom";
import PersonIcon from "@mui/icons-material/Person";
import { BoirFilingChecklistStepper } from "./components/BOIRFilingChecklistStepper";

const exemptionDetails = [
  {
    title: "Large Operating Company",
    conditions: [
      "(a) The entity employs more than 20 full-time employees.",
      "(b) More than 20 full-time employees are employed in the United States.",
      "(c) The entity regularly conducts its business at a physical location in the United States that the entity owns or leases.",
      "(d) The entity filed a Federal income tax return in the United States for the previous year demonstrating more than $5,000,000 in gross receipts or sales.",
    ],
  },
  {
    title: "Inactive Entity",
    conditions: [
      "(a) The entity was in existence on or before January 1, 2020.",
      "(b) The entity is not engaged in any active business.",
      "(c) No 'foreign person' has any ownership interest in the entity.",
      "(d) There has been no change in ownership during the past twelve months.",
      "(e) No funds greater than $1,000 were received by the entity in the past twelve months.",
      "(f) The entity does not own or hold any assets.",
    ],
  },
];

export const Survey = ({ setActiveStep }) => {
  const dispatch = useDispatch();
  const user = useSelector(selectUserInstance);
  const { isAuthenticated } = useSelector((state) => state.user.auth);
  const [selectedOption, setSelectedOption] = useState("");
  const [expanded, setExpanded] = useState(false);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(
    user.hasCompletedSurvey ? questions.length - 1 : 0
  );
  const [message, setMessage] = useState(user.hasCompletedSurvey ? DEFAULT_MESSAGE : "");
  const [localSurveyData, setLocalSurveyData] = useState({});
  const [boirStepperCompleted, setBoirStepperCompleted] = useState(false);

  // Save survey response to localStorage
  const saveSurveyToLocalStorage = (answer, questionIndex) => {
    const updatedSurveyData = {
      ...localSurveyData,
      [questionIndex]: answer,
    };
    setLocalSurveyData(updatedSurveyData);
    localStorage.setItem("surveyData", JSON.stringify(updatedSurveyData));
  };

  const resetSurvey = () => {
    setCurrentQuestionIndex(0);
    setSelectedOption("");
    setMessage("");
    setExpanded(false);
    dispatch(
      updateUser({
        ...user,
        hasCompletedSurvey: false,
      })
    );
    localStorage.removeItem("surveyData");
    setLocalSurveyData({});
  };

  const handleResponse = (answer, question, logic) => {
    if (answer === YES) {
      setMessage(questions[currentQuestionIndex].yesMessage);
    } else if (answer === NO) {
      setMessage(questions[currentQuestionIndex].noMessage);
    }

    saveSurveyToLocalStorage(answer, currentQuestionIndex);

    if (currentQuestionIndex + logic >= MAX) {
      setCurrentQuestionIndex(questions.length);
      dispatch(
        updateUser({
          ...user,
          hasCompletedSurvey: true,
        })
      );
    } else if (currentQuestionIndex + logic < questions.length) {
      setCurrentQuestionIndex(currentQuestionIndex + logic);
    }
  };

  const handleCompleteBOI = () => {
    setActiveStep(1);
  };

  const handleChangeAccordion = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Box display="flex" flexDirection="row" justifyContent="space-between" gap={2}>
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={2}
        sx={{ flex: 3 }}
      >
        <Card sx={{ width: "100%" }}>
          <CardHeader title="Evaluate Your FinCEN Filing Obligations" />
          <CardContent>
            <LinearProgress
              variant="determinate"
              value={message ? 100 : (currentQuestionIndex / questions.length) * 100}
              sx={{ mb: 3 }}
            />
            {!message ? (
              questions.map((q, index) => (
                <div key={index}>
                  {currentQuestionIndex === index && (
                    <Question
                      question={q}
                      handleResponse={handleResponse}
                      selectedOption={localSurveyData[index] || selectedOption} // Load from localStorage if available
                    />
                  )}
                </div>
              ))
            ) : (
              <>
                <Typography paragraph>{message}</Typography>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Button variant="contained" onClick={resetSurvey}>
                    Update Your Answers
                  </Button>
                  <Button
                    variant="contained"
                    onClick={handleCompleteBOI}
                    disabled={!boirStepperCompleted}
                  >
                    Complete Your BOI Report
                  </Button>
                </Box>
              </>
            )}
          </CardContent>
        </Card>

        <BoirFilingChecklistStepper onComplete={setBoirStepperCompleted} />

        {/* Understanding Primary Exemptions section */}
        <Card sx={{ width: "100%" }}>
          <CardHeader title="Understanding Primary Exemptions" />
          <CardContent>
            {exemptionDetails.map((exemption, index) => (
              <Accordion
                expanded={expanded === `panel${index}`}
                onChange={handleChangeAccordion(`panel${index}`)}
                key={index}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography>{exemption.title}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <List>
                    {exemption.conditions.map((condition, idx) => (
                      <ListItem key={idx}>
                        <ListItemText primary={condition} />
                      </ListItem>
                    ))}
                  </List>
                  <Typography variant="body2" sx={{ mt: 2 }}>
                    All conditions must be met for this exemption to apply.
                  </Typography>
                </AccordionDetails>
              </Accordion>
            ))}
          </CardContent>
        </Card>
      </Stack>

      {/* User Authentication Box */}
      <Box
        sx={{
          flex: 1,
          maxWidth: "200px",
          p: 2,
          backgroundColor: "#e3f2fd",
          border: "1px solid",
          borderColor: "grey.300",
          borderRadius: 1,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          ml: 2,
        }}
      >
        <PersonIcon sx={{ fontSize: 40, color: "primary.main", mb: 1 }} />
        {isAuthenticated ? (
          <>
            <Typography variant="h6" align="center" gutterBottom>
              Welcome Back!
            </Typography>
            <Typography variant="body1" align="center" gutterBottom>
              To follow the progress of your submission, please visit your account dashboard.
            </Typography>
            <Button variant="contained" component={Link} to="/dashboard" sx={{ mt: 2 }}>
              Go to Dashboard
            </Button>
            <Typography variant="body2" align="center" sx={{ mt: 2 }}>
              If you still need to complete your BOIR Report, please access the online form provided
              below.
            </Typography>
            <Button
              variant="contained"
              sx={{ mt: 2 }}
              onClick={() => setActiveStep(1)}
              disabled={!boirStepperCompleted}
            >
              Complete your BOI report
            </Button>
          </>
        ) : (
          <>
            <Typography variant="h6" align="center" gutterBottom>
              Already Have an Account?
            </Typography>
            <Typography variant="body1" align="center" gutterBottom>
              Sign in and submit your BOI Report
            </Typography>
            <Button variant="contained" component={Link} to="/sign-in" sx={{ mt: 2 }}>
              Sign In
            </Button>
            <Typography variant="body1" align="center" gutterBottom sx={{ mt: 2 }}>
              Don&#39;t have an account? Sign up here.
            </Typography>
            <Button variant="contained" component={Link} to="/sign-up" sx={{ mt: 2 }}>
              Sign Up
            </Button>
          </>
        )}
      </Box>
    </Box>
  );
};
